import Vue from "vue";
import { isNftStrictSwap, isPrimeSwap, isPrimeSwapV1 } from "@blockwell/swap";
import { getChain } from "@blockwell/chains";
import NftIcon from "@/components/NftIcon.vue";
import { addTokenURIs } from "@blockwell/nft";
import Dapp from "@/assets/lib/Dapp";
import LoadingButton from "@/components/LoadingButton.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import { mapGetters } from "vuex";
import ProgressCircle from "@/components/ProgressCircle.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import { tokenLockAt } from "@/views/happs/locks/lockslib";
import BigNumber from "bignumber.js";
import moment from "moment";
import TokenLockView from "@/views/happs/multichain/TokenLockView.vue";
export default Vue.extend({
    name: "PendingSwap",
    components: { TokenLockView, TokenAmount, ProgressCircle, DumbappPopover, LoadingButton, NftIcon },
    props: {
        account: String,
        pending: {
            type: Object,
        },
    },
    inject: ["token"],
    data() {
        return {
            contract: null,
            items: null,
            value: null,
            loadingSignature: false,
            signature: null,
            showDumbapp: false,
            dumbapp: null
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        api() {
            return this.$store.getters["user/api"];
        },
        contractName() {
            return this.contract?.name;
        },
        fromChain() {
            return getChain(this.pending.chainId);
        },
        toChain() {
            return getChain(this.pending.toChainId);
        },
        dumbappValues() {
            if (!this.signature) {
                return {};
            }
            return {
                chainId: this.signature.chainId,
                contractAddress: this.signature.contractAddress,
                swap: Object.values(this.signature.swap),
                v: this.signature.signature.v,
                r: this.signature.signature.r,
                s: this.signature.signature.s,
            };
        },
        trackingId() {
            return "pending-swap";
        },
        tracked() {
            let tracks = this.tracking[this.trackingId];
            if (tracks) {
                let track = tracks.find(it => it.swapId === this.pending?.swapId);
                return track && track.status !== "error";
            }
            return false;
        },
        token() {
            return {
                symbol: this.contract?.parameters?.symbol,
                decimals: this.contract?.parameters?.decimals,
                places: null
            };
        },
        tokenLock() {
            let swap = this.pending?.swap;
            if (isPrimeSwap(swap) && swap.periods !== "0") {
                return tokenLockAt({
                    expiration: moment().add(parseInt(swap.periodLength), "seconds"),
                    periods: parseInt(swap.periods),
                    periodLength: parseInt(swap.periodLength),
                    value: new BigNumber(swap.value).minus(swap.unlockedValue)
                }, moment());
            }
            return null;
        }
    },
    watch: {
        pending: {
            immediate: true,
            deep: true,
            handler(val) {
                const api = this.api;
                if (val) {
                    api.contracts
                        .getContract({
                        chainId: val.toChainId,
                        address: val.toTokenAddress,
                    })
                        .then((it) => (this.contract = it));
                }
                if (isNftStrictSwap(this.pending.swap)) {
                    let net = Dapp.getNetwork(this.pending.toChainId);
                    let web3 = net.web3;
                    let items = this.pending.swap.tokenIds.map((tokenId) => {
                        return {
                            tokenId,
                            chainId: this.pending.toChainId,
                            nft: this.pending.toTokenAddress,
                        };
                    });
                    api.nftStrictSwapDumbapp().then(it => this.dumbapp = it);
                    addTokenURIs(web3, items).then(() => {
                        this.items = items;
                    });
                }
                else if (isPrimeSwap(this.pending.swap)) {
                    this.value = this.pending.swap.value;
                    api.signedSwapDumbapp().then(it => this.dumbapp = it);
                }
                else if (isPrimeSwapV1(this.pending.swap)) {
                    this.value = this.pending.swap.value;
                }
            },
        },
    },
    methods: {
        click() {
            this.loadingSignature = true;
            let api = this.api;
            api.signPendingSwap(this.pending.swapId)
                .then((res) => {
                this.signature = res;
                this.showDumbapp = true;
            })
                .finally(() => (this.loadingSignature = false));
        },
        addSubmitted(submission) {
            let swapId = this.pending.swapId;
            let payload = {
                key: this.trackingId,
                data: {
                    id: submission.id,
                    swapId,
                },
            };
            this.$store.dispatch("dumbapp/trackSubmission", payload);
        },
    },
});
