import { render, staticRenderFns } from "./ItemDrops.vue?vue&type=template&id=92db5ede&scoped=true&"
import script from "./ItemDrops.vue?vue&type=script&lang=ts&"
export * from "./ItemDrops.vue?vue&type=script&lang=ts&"
import style0 from "./ItemDrops.vue?vue&type=style&index=0&id=92db5ede&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92db5ede",
  null
  
)

export default component.exports