import Vue from "vue";
import WhitelabelHeading from "../../../layouts/WhitelabelHeading.vue";
import Heading from "../../../layouts/Heading.vue";
import Clipboard from "../../../components/Clipboard.vue";
import ContractDropdown from "../components/ContractDropdown.vue";
import HappMixin from "../Happ";
import ContractLoader from "../components/ContractLoader.vue";
import { getOldVotingData } from "./old-suggestions";
import BookContent from "../../../components/BookContent.vue";
import { omit } from "rambdax";
import ProgressCircle from "../../../components/ProgressCircle.vue";
import Suggestion from "./Suggestion.vue";
import ClampedText from "../../../components/ClampedText.vue";
import CreateInsert from "./CreateInsert.vue";
import DumbappModal from "../../../components/DumbappModal.vue";
import Modal from "../../../components/Modal.vue";
import VoteComment from "./VoteComment.vue";
import NoneInsert from "./NoneInsert.vue";
import { capitalCase } from "change-case";
import Markdown from "../../../components/Markdown.vue";
import SuggestionDetail from "@/views/happs/vote/SuggestionDetail.vue";
import { getNetwork } from "@/assets/lib/networks";
import { addressEqual, chainEqual } from "@blockwell/chains";
export default Vue.extend({
    components: {
        SuggestionDetail,
        Markdown,
        NoneInsert,
        VoteComment,
        Modal,
        DumbappModal,
        CreateInsert,
        ClampedText,
        Suggestion,
        ProgressCircle,
        BookContent,
        ContractLoader,
        ContractDropdown,
        Clipboard,
        WhitelabelHeading,
        Heading,
    },
    mixins: [HappMixin],
    props: {
        whitelabel: Boolean,
        cssVars: {
            type: Object,
            default() {
                return {};
            },
        },
        query: Object,
    },
    data() {
        let loaderFunction;
        loaderFunction = getOldVotingData;
        return {
            type: "vote",
            deployerAddress: [
                "0xC0DEeD81ABD93Fc2Ac38a439da811B36532D7BA8",
                "0x9419cD99337bEb52E87a72E0B81AcDC74ffb0C62",
            ],
            commonContracts: [
                {
                    name: "Shopin Tokenholder Voices",
                    bwns: "shopin",
                },
                {
                    name: "Rinkeby Roar",
                    address: "0xE595564689D6E0206b095915C219a8c7a130cF7B",
                    network: 4,
                },
            ],
            errorMessage: null,
            votingData: null,
            dumbapps: {},
            showCreate: false,
            openSuggestion: null,
            comments: {
                show: false,
                id: null,
                list: [],
            },
            loaderFunction,
            loader: null,
            dumbappsUrl: "api/voting/:contractId/dumbapps",
        };
    },
    computed: {
        address() {
            return this.happ?.address || "default";
        },
        addressLower() {
            return this.address?.toLowerCase();
        },
        layoutComponent() {
            if (this.whitelabel) {
                return WhitelabelHeading;
            }
            else {
                return Heading;
            }
        },
        contractLoaded() {
            return this.contract.address && !!this.votingData?.contractData;
        },
        loadingSuggestions() {
            return this.happLoading || !!this.loader?.loadingData;
        },
        suggestionsList() {
            let list = this.votingData?.suggestions;
            if (list) {
                if (list.length > 8) {
                    return [
                        ...list.slice(0, 2),
                        { type: "create", id: "create1" },
                        ...list.slice(2),
                        { type: "create", id: "create2" },
                    ];
                }
                if (list.length > 3) {
                    return [...list.slice(0, 2), { type: "create" }, ...list.slice(2)];
                }
                if (list.length === 0) {
                    return [
                        { type: "none", id: "none" },
                        { type: "create", id: "create1" },
                    ];
                }
                return [...list, { type: "create", id: "create1" }];
            }
            return [];
        },
        commentsDeps() {
            if (this.$route.query.comments && this.votingData?.suggestions) {
                return {
                    query: this.$route.query.comments,
                    list: this.votingData?.suggestions,
                };
            }
            return null;
        },
        language() {
            return {
                vote: this.votingData?.config?.language?.vote || "vote",
                votes: this.votingData?.config?.language?.votes || "votes",
                voted: this.votingData?.config?.language?.voted || "voted",
                suggestion: this.votingData?.config?.language?.suggestion || "suggestion",
                suggestions: this.votingData?.config?.language?.suggestions || "suggestions",
            };
        },
        description() {
            return this.votingData?.config?.description;
        },
        csvLink() {
            if (this.contract.address) {
                return `https://api.blockwell.ai/v2/vote/export/${getNetwork(this.contract.network).alias}.${this.contract.address}`;
            }
            return "";
        }
    },
    watch: {
        commentsDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val) {
                    let id = parseInt(val.query);
                    this.openSuggestion = val.list.find(it => it.id === id);
                }
                else {
                    this.openSuggestion = null;
                }
            },
        },
    },
    methods: {
        suggestionComponent(suggestion) {
            if ("type" in suggestion) {
                if (suggestion.type === "create") {
                    return CreateInsert;
                }
                else {
                    return NoneInsert;
                }
            }
            else {
                return Suggestion;
            }
        },
        onLoad(contract) {
            if (this.loggedIn) {
                this.api.addToContractHistory(this.$route.fullPath, "Voting happ", contract.address, contract.network);
            }
        },
        async onData(data) {
            if (data) {
                this.votingData = data;
                document.title = `Voting for ${data.contractData.name} | Blockwell Wallet`;
            }
            else {
                this.votingData = null;
                this.dumbapps = {};
                document.title = "Voting | Blockwell Wallet";
            }
        },
        onDumbapps(dumbapps) {
            this.dumbapps = dumbapps;
        },
        create() {
            this.showCreate = true;
        },
        reset() {
            this.$router.push("/vote");
            this.interval?.clear();
            this.interval = null;
        },
        dumbappCompleted(submission) {
            let i = 0;
            let reload = false;
            for (let step of submission.data.steps) {
                let dumbappStep = submission.dumbapp.steps[i];
                if (chainEqual(dumbappStep.network, this.happ.network.networkId) &&
                    addressEqual(dumbappStep.address, this.votingData?.contractData?.address)) {
                    reload = true;
                }
                ++i;
            }
            if (reload) {
                this.interval?.runNow();
            }
        },
        openComments(id) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    comments: id.toString(),
                },
            });
        },
        closeComments() {
            let query = this.$route.query;
            if (query.comments) {
                this.$router.push({ query: omit(["comments"], query) });
            }
        },
        capitalize(text) {
            return capitalCase(text);
        },
    },
    mounted() {
        this.$bus.on("dumbapp-completed", this.dumbappCompleted);
    },
    destroyed() {
        this.loader?.destroy();
        this.$bus.off("dumbapp-completed", this.dumbappCompleted);
    },
});
