import Vue from "vue";
import TokenAmount from "@/components/TokenAmount.vue";
export default Vue.extend({
    name: "TokenLockView",
    components: { TokenAmount },
    props: {
        lock: {
            type: Object,
        },
        willLock: Boolean,
    },
    data() {
        return {};
    },
});
