import {loadBookContent} from "@/views/happs/book/lib/booklib";
import {mapActions, mapGetters} from "vuex";

export default {
    props: {
        address: String,
        network: [String, Number],
        folder: String,
        file: {
            type: [String, null],
            default: null
        },
        blocks: Boolean
    },
    data() {
        return {
            contentFetched: false,
            contentRendered: false,
            content: null,
            md: null,
            noAccessError: false,
            markdownHtml: ""
        }
    },
    computed: {
        ...mapGetters('user', ['loggedIn']),
        contentDependencies() {
            return {
                address: this.address,
                network: this.network,
                folder: this.folder,
                file: this.file,
                blocks: this.blocks
            }
        },
        renderDependencies() {
            return {
                content: this.content,
                hasContent: this.hasContent,
                md: this.md,
                loggedIn: this.loggedIn
            }
        },
        hasContent() {
            let files = this.content;
            if (!files) {
                return false;
            }
            for (let it of files) {
                if (it.data !== undefined) {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        contentDependencies: {
            deep: true,
            immediate: true,
            handler(val) {
                this.loadContent(val);
            }
        },
        renderDependencies: {
            immediate: true,
            handler(val) {
                if (val.md) {
                    this.renderContent(val.content);
                }
            }
        },
        markdownHtml: {
            immediate: true,
            handler(val) {
                if (this.$refs.markdown) {
                    this.$refs.markdown.innerHTML = val;
                }
            }
        }
    },
    methods: {
        ...mapActions('autocrypt', ['getKey']),
        loadContent(data) {
            if (!data.address || !data.network) {
                return [];
            }
            (async () => {
                // If it's not null, but empty, then the File is still being determined. Skip for now.
                if (data.file === "") {
                    return [];
                }
                this.content = await loadBookContent(data.network, data.address, data.folder, data.file);
            })()
                .finally(() => this.contentFetched = true)
                .catch(console.error);
        },
        renderContent(content) {
            if (content) {
                this.$nextTick(async () => {
                    this.noAccessError = false;
                    try {
                        let plainText = [];
                        for (let it of content) {
                            if (!it.data) {
                                continue;
                            }
                            if (it.encoding?.parameters?.crypt === "auto") {
                                let params = it.encoding.parameters;
                                let key = await this.getKey({
                                    tokenAddress: params.token,
                                    tokenNetwork: params.network,
                                    contractAddress: this.address,
                                    contractNetwork: this.network
                                });

                                if (key) {
                                    let data = await Promise.resolve(this.crypt.decrypt(it.data, {password: key}));
                                    plainText.push({
                                        ...it,
                                        data
                                    })
                                } else {
                                    plainText.push({
                                        ...it,
                                        data: `Decryption failed for file ${it.name}\n\n`
                                    });
                                    this.noAccessError = true;
                                }

                            } else {
                                plainText.push(it);
                            }
                        }

                        let html = '';

                        if (plainText.length > 0) {
                            if (this.blocks) {
                                for (let it of plainText) {
                                    let rendered = this.md.render(it.data || "");
                                    html += '<div class="markdown-block">' + rendered + '</div>';
                                }
                            } else {
                                html = this.md.render(plainText.map(it => it.data).join("\n"));
                            }
                            if (this.data) {
                                html = Mustache.render(html, this.data);
                            }
                        }
                        this.markdownHtml = html;
                    } catch (err) {
                        console.error(err);
                    }
                    this.contentRendered = true;
                });
            }
        }
    }
}
