<template>
    <div class="nf-token-card">
        <ProgressCircle v-if="pending" class="pending-progress" />
        <div class="image-wrap" :class="{pending: pending}">
            <img v-if="image" :src="image">
            <div v-else class="no-image">
                <span class="material-icons">image_not_supported</span>
            </div>
        </div>
        <div class="token-info">
            <div v-if="pending" class="pending-confirmation">
                <p>Pending confirmation...</p>
            </div>
            <div v-else class="token-id">
                <span class="contract-name">{{ nftData.name }}</span>
                <span class="hash-sign">#</span>
                <span class="token-id">{{ token.tokenId }}</span>
            </div>
            <div v-if="pending" class="opensea-note">
                <strong>Note</strong>: it may take a few minutes after confirmation for the token to appear on
                OpenSea
            </div>
            <template v-else>
                <div class="token-name">
                    {{ name }}
                </div>
                <div class="token-owner">
                    Owned by
                    <Clipboard ellipsize :address="token.owner" :network="nftData.network.networkId" />
                </div>
                <div>
                    <a v-if="openseaLink" :href="openseaLink" target="_blank" class="view-on-opensea">
                        View On OpenSea
                        <i class="material-icons">launch</i>
                    </a>
                </div>
                <div v-if="dumbappLink" class="transfer-token">
                    <router-link :to="dumbappLink" class="btn btn-sm btn-outline-primary">Send</router-link>
                    <div class="formulate-input-help">
                        If you own the token you can send it.
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import ProgressCircle from "@/components/ProgressCircle";
import { NftData, NfToken } from "@/views/happs/nft/lib/nftlib";
import { mapGetters } from "vuex";

export default {
    name: "NfToken",
    components: { Clipboard, ProgressCircle },
    props: {
        nftData: NftData,
        token: NfToken,
        dumbapps: Object
    },
    data() {
        return {
            metadata: {}
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        pending() {
            return !!this.token.pending;
        },
        name() {
            if (this.pending) {
                return this.token.pending.name;
            }
            return this.metadata?.name;
        },
        image() {
            if (this.pending) {
                return this.token.pending.image;
            }
            return this.metadata?.image;
        },
        openseaLink() {
            let baseUrl;
            if (this.nftData.network.networkId === 4) {
                baseUrl = "https://testnets.opensea.io/";
            } else if (this.nftData.network.networkId === 1) {
                baseUrl = "https://opensea.io";
            } else {
                return null;
            }

            return `${baseUrl}assets/${this.nftData.address}/${this.token.tokenId}`;
        },
        dumbappLink() {
            if (this.dumbapps?.transfer) {
                return `/${this.dumbapps.transfer.shortcode}?tokenId=${this.token.tokenId}`;
            }
        }
    },
    watch: {
        token: {
            immediate: true,
            handler(val) {
                if (val.uri) {
                    this.api.client.get(val.uri).then(it => this.metadata = it.data).catch(console.error);
                } else {
                    this.metadata = {};
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.nf-token-card {
    border: 1px solid #ddd;
    position: relative;
}

.image-wrap {
    height: 200px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep( img ) {
        max-width: 75%;
        max-height: 120px;
    }

    &.pending img {
        opacity: 0.4;
    }
}

.token-info {
    padding: 10px 15px;
    border-top: 1px solid #ddd;
}

.token-id {
    color: $text-muted;
    font-size: 0.9em;
    margin-bottom: 0.6em;
}

.hash-sign {
    margin-left: 6px;
}

.token-name {
    font-size: 1.4em;
}

.view-on-opensea {
    margin-top: 6px;
    font-size: 0.9em;

    .material-icons {
        color: $primary;
    }
}

.pending-confirmation {
    color: $text-muted;
}

.pending-progress {
    position: absolute;
    top: 80px;
    left: calc(50% - 20px);
}

.token-owner {
    font-size: 0.8em;
    margin: 0.9em 0;
    opacity: 0.8;
}

</style>
