const encodingDescriptions = {
    application: {
        json: {
            name: "JSON",
            description: "JavaScript Object Notation, commonly used for data that needs to be used automatically."
        }
    },
    text: {
        plain: {
            name: "Plain Text",
            description: "Plain text with no special formatting."
        },
        markdown: {
            name: "Markdown",
            description: "Markdown is a simple way to format text that's easy to edit and use."
        },
        tasks: {
            name: "Tasks",
            description: "A list of tasks."
        }
    }
};

export class BookEncoding {

    /**
     * Parses an RFC 2046 style Media Type, aka. MIME type
     *
     * @param {string} encoding
     * @return {BookEncoding}
     */
    static parse(encoding) {
        if (!encoding) {
            return this.plainText();
        }

        let types = /^([a-z0-9][a-z0-9-]*)(\/([a-z0-9][a-z0-9-+.]*))?\s*/.exec(encoding);

        if (!types) {
            return this.plainText();
        }

        let type = types[1];
        let subtype = types[3];

        let remainder = encoding.slice(types[0].length);

        let parameters;
        for (let match of remainder.matchAll(/\s*([a-z0-9][a-z0-9-]*)=([^;]*)/ig)) {
            if (!parameters) {
                parameters = {};
            }
            parameters[match[1]] = match[2].trim();
        }

        return new BookEncoding(type, subtype, parameters);
    }

    static plainText() {
        return new BookEncoding("text", "plain");
    }

    constructor(type, subtype, parameters = null) {
        this.type = type;
        this.subtype = subtype;
        this.parameters = parameters;
        this.name = this.fullType();
        this.description = null;
    }

    /**
     * Returns the full type without parameters.
     *
     * @return {string}
     */
    fullType() {
        let full = this.type;
        if (this.subtype) {
            full += "/" + this.subtype;
        }
        return full;
    }

    /**
     * Checks if this encoding matches the given parameters.
     *
     * @return {boolean}
     */
    is(type, subtype = null) {
        if (this.type !== type) {
            return false;
        }
        return !(subtype !== null && this.subtype !== subtype);
    }

    isEncrypted() {
        return !!this.parameter('crypt');
    }

    encryption() {
        return this.parameter('crypt');
    }

    toString() {
        let val = this.fullType();

        if (this.parameters) {
            return [val].concat(Object.entries(this.parameters).map(entry => `${entry[0]}=${entry[1]}`)).join('; ');
        }
        return val;
    }

    parameter(key) {
        if (this.parameters) {
            return this.parameters[key];
        } else {
            return null;
        }
    }

    set(key, value) {
        if (!value) {
            if (this.parameters && this.parameters[key]) {
                delete this.parameters[key];
            }
        } else {
            if (!this.parameters) {
                this.parameters = {};
            }
            this.parameters[key] = value;
        }
    }

    clone() {
        return BookEncoding.parse(this.toString());
    }
}

/**
 *
 * @param {BookEncoding} encoding
 * @return {string}
 */
export function encodingDescription(encoding) {
    let sub = encodingDescriptions[encoding.type];
    if (!sub) {
        return encoding.fullType();
    }

    if (sub[encoding.subtype]) {
        if (sub[encoding.subtype].name) {
            return sub[encoding.subtype].name;
        } else {
            return sub[encoding.subtype];
        }
    } else {
        return encoding.fullType();
    }
}

/**
 *
 * @return {BookEncoding[]}
 */
export function allEncodings() {
    let list = [];
    for (let [type, value] of Object.entries(encodingDescriptions)) {
        for (let [subtype, data] of Object.entries(value)) {
            let enc = new BookEncoding(type, subtype, data.parameters);
            if (data.name) {
                enc.name = data.name;
                enc.description = data.description;
            } else {
                enc.name = data;
            }
            list.push(enc);
        }
    }
    return list;
}
