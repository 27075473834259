import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import { exportLottoRates } from "@/views/happs/lotto/lotto-lib";
import { saveAs } from "file-saver";
import BigNumber from "bignumber.js";
import { capitalCase } from "change-case";
import Modal from "@/components/Modal.vue";
import ConfigureWinRates from "@/views/happs/lotto/ConfigureWinRates.vue";
import LottoProfit from "@/views/happs/lotto/LottoProfit.vue";
export default Vue.extend({
    name: "WinRates",
    components: { LottoProfit, ConfigureWinRates, Modal, DumbappModal, TokenContract, TokenAmount, Info, InfoField, InfoSection },
    props: {
        context: {
            type: Object
        }
    },
    data() {
        return {
            showWinRates: false
        };
    },
    computed: {
        tokenName() {
            return this.context?.token?.name || "tokens";
        },
        totalWinRate() {
            const context = this.context;
            return context?.data?.returns.reduce((acc, it) => {
                return acc.plus(it.chance);
            }, new BigNumber(0));
        }
    },
    methods: {
        exportWinRates() {
            exportLottoRates(this.context.data, this.context.token).then((csv) => {
                let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
                let date = new Date();
                saveAs(blob, `${capitalCase(this.context.token.name)}-win-rate-export-${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.csv`);
            });
        },
    },
});
