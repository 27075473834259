<template>
    <div class="mint-nft">
        <h3>Mint a new token</h3>
        <p>
            Minting a new token adds a new unique item to {{ nftData.name }}. Fill in the details below.
        </p>
        <div v-if="!loggedIn">
            <p><strong>You must be logged in to mint a token.</strong></p>
            <LoginForm/>
        </div>
        <template v-else>
            <p v-if="!canMint" class="error">
                You are not allowed to mint tokens on this contract. You will need to ask an admin to give you
                that right.
            </p>
            <FormulateInput
                label="Name"
                help="This is the name of the item this token represents."
                type="text"
                name="name"
                validation="required"
                v-model="name"
                @blur="blur"
            />
            <FormulateInput
                label="Mint To Wallet (Optional)"
                help="Wallet to mint the token to. If left empty, it will be minted to your wallet."
                type="text"
                name="account"
                v-model="wallet"
                @blur="blur"
            />
            <FormulateInput
                label="Image"
                help="Paste in a link to the image for this item."
                type="text"
                name="image"
                v-model="image"
                @blur="blur"
            />
            <div v-if="image" class="image-preview-wrap">
                <h6>Image Preview</h6>
                <div v-if="imagePreview" class="image-preview">
                    <img :src="imagePreview">
                </div>
                <div v-else>
                    Not a valid image link, please use a web link to an image.
                </div>
            </div>
            <h6>Description</h6>
            <MarkdownEditor :content.sync="description" min-height="60"
                            @blur="blur"
                            :toolbar="['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                            'link', 'image', 'preview', 'guide']"
            />
            <div class="formulate-input-help">
                The description is shown in the details of this item.
            </div>
            <h5>Attributes and Traits</h5>
            <p>You can add Properties, Stats, Levels and Boosts to your token.</p>
            <FormulateInput
                type="group"
                name="attributes"
                :repeatable="true"
                v-model="attributes"
                remove-label="Remove Attribute"
                remove-position="after"
                add-label="+ Add Attribute">
                <div class="attribute">
                    <FormulateInput
                        name="trait_type"
                        label="Attribute name"
                        help="Name of the attribute."
                        @blur="blur"/>
                    <FormulateInput
                        type="select"
                        name="display_type"
                        placeholder="Select a type"
                        label="Type"
                        help="Determines how the attribute is displayed."
                        :options="{
                            property: 'Property',
                            number: 'Stat',
                            level: 'Level',
                            boost_number: 'Boost Number',
                            boost_percentage: 'Boost Percentage'
                        }"
                        @change="blur"
                        />
                    <FormulateInput
                        name="value"
                        label="Value"
                        help="Attribute value. This should be a number for Stats, Levels and Boosts, and text for Properties."
                        @blur="blur"
                        />
                </div>
            </FormulateInput>

            <LoadingButton
                class="mint-submit"
                :loading="loading"
                :disabled="!name || loading"
                small
                @click="submit">Submit</LoadingButton>
            <DumbappPopover
                :instance="mintDumbapp"
                :show.sync="showDumbapp"
                :values="{
                    tokenId,
                    uri: tokenUri,
                    to: wallet
                }"
                @submitted="submitted"
                />
        </template>
    </div>
</template>

<script>
import { DumbappSubmission } from "@blockwell/dumbapp";
import DumbappPopover from "@/components/DumbappPopover";
import LoadingButton from "@/components/LoadingButton";
import LoginForm from "@/components/LoginForm";
import {debounce} from "@/lib/vutil";
import MarkdownEditor from "@/views/happs/book/editors/MarkdownEditor";
import {nextNftId, NftData} from "@/views/happs/nft/lib/nftlib";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MintNft",
    components: {DumbappPopover, LoadingButton, LoginForm, MarkdownEditor},
    props: {
        nftData: NftData,
        dumbapps: Object
    },
    data() {
        return {
            name: "",
            description: "",
            image: "",
            wallet: "",
            attributes: [],
            imagePreview: null,
            tokenId: null,
            tokenUri: null,
            loading: false,
            showDumbapp: false
        };
    },
    computed: {
        ...mapGetters('user', ['loggedIn', 'api']),
        canMint() {
            return this.nftData.address === "0xbb64d2e7fcca5dcbb84ce75aef75956534ecc85c"
                || this.nftData.admin || this.nftData.minter;
        },
        mintDumbapp() {
            if (this.wallet) {
                return this.dumbapps.mintOther;
            } else {
                return this.dumbapps.mintWithTokenURI;
            }
        }
    },
    watch: {
        image: debounce(function (val) {
            if (/^https?:\/\//i.test(val)) {
                this.imagePreview = val;
            } else {
                this.imagePreview = null;
            }
        })
    },
    methods: {
        ...mapActions('dumbapp', ['trackSubmission']),
        ...mapActions('drafts', ['saveNftDraft', 'clearNftDraft']),
        blur() {
            this.saveNftDraft({
                contractId: this.nftData.contractId,
                name: this.name,
                description: this.description,
                image: this.image,
                attributes: this.attributes
            })
        },
        submit() {
            this.loading = true;
            (async () => {
                if (this.tokenId === null) {
                    this.tokenId = await nextNftId(this.nftData.network, this.nftData.address);
                }
                console.log('tokenId', this.tokenId);

                let attributes = this.attributes.map(it => {
                    let data = {
                        ...it
                    }
                    if (data.display_type === "property" || data.display_type === "level") {
                        delete data.display_type;
                    }
                    return data;
                })

                if (this.tokenUri === null) {
                    this.tokenUri = await this.api.addNftMetadata(
                        this.nftData.contractId, this.tokenId, this.name, this.description, this.image, attributes);
                }
                console.log('tokenUri', this.tokenUri);

                console.log(this.nftData);
                this.showDumbapp = true;
            })()
                .catch(console.error)
                .finally(() => this.loading = false);
        },
        submitted(submission) {
            this.trackSubmission({
                key: ['nft-mint', this.nftData.network.networkId, this.nftData.address].join('-'),
                data: {
                    id: submission.id,
                    name: this.name,
                    image: this.image,
                    tokenId: this.tokenId
                }
            });
            this.clearNftDraft(this.nftData.contractId);
            this.$emit('submitted');
        }
    },
    created() {
        let draft = this.$store.state.drafts.drafts[`${this.nftData.contractId}-mint`];
        if (draft && !this.name && !this.description && !this.image) {
            console.log('draft', draft);
            this.name = draft.name;
            this.description = draft.description;
            this.image = draft.image;
            this.attributes = draft.attributes || [];
        }
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.mint-nft {
    ::v-deep( .editor-toolbar ) {

        background: #efefef;
        font-size: 0.8em;
        padding: 0;

        &::before, &::after {
            content: none;
        }
    }
}

.image-preview-wrap {
    margin-bottom: 30px;
}

.image-preview {

    ::v-deep( img ) {
        max-height: 80px;
        max-width: 100%;
    }
}

.mint-submit {
    margin-top: 15px;
}

.attribute {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}

</style>
