import Vue from "vue";
import Info from "../../../components/Info.vue";
import ClampedText from "../../../components/ClampedText.vue";
import DumbappPopover from "../../../components/DumbappPopover.vue";
import DumbappModal from "../../../components/DumbappModal.vue";
export default Vue.extend({
    name: "NoneInsert",
    components: { DumbappModal, DumbappPopover, ClampedText, Info },
    props: {
        suggestion: {
            type: Object,
        },
        language: {
            type: Object
        }
    },
    data() {
        return {};
    },
    methods: {
        create() {
            this.$emit("create");
        }
    },
});
