import Vue from "vue";
export default Vue.extend({
    name: "VoteComment",
    props: {
        comment: {
            type: Object
        }
    },
    data() {
        return {};
    },
});
