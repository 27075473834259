import Dapp from "@/assets/lib/Dapp";
import { getNetwork } from "@/assets/lib/networks";
import { Batcher } from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import FileEditor from "@/views/happs/book/lib/FileEditor";
import { uniq } from "rambdax";
import abi from "../../../../assets/data/BaseBook.abi.json";
import { BookEncoding } from "./BookEncoding";

/**
 *
 * @param {Happ} happ
 * @return {Promise<BookData>}
 */
export async function loadBook(happ) {
    let batcher = new Batcher(happ.web3)

        .setContract(abi, happ.address)
        .addInt("bwver")
        .add("name")
        .add("defaultEncoding")
        .add("defaultAccessToken")
        .add("authoringRestricted")
        .add("creatorEditing")
        .add("directEditing");

    let data = await batcher.execute();

    data.defaultEncoding = BookEncoding.parse(data.defaultEncoding);
    return data;
}

/**
 *
 * @param {Happ} happ
 * @param {BookEncoding} defaultEncoding
 * @return {Promise<BookFolder[]>}
 */
export async function loadFolders(happ, defaultEncoding = null) {
    let ids = [];
    let contract = new happ.web3.eth.Contract(abi, happ.address);
    let count = await contract.methods.getFolderCount().call();

    let batch = new happ.web3.BatchRequest();

    for (let i = 0; i < count; i++) {
        batch.add(contract.methods.getFolderName(i).call.request());
        batch.add(contract.methods.getFolderSizeByIndex(i).call.request());
        batch.add(contract.methods.getEncodingByIndex(i).call.request());
        ids.push(i);
    }

    let res = (await batch.execute()).response;
    let i = 0;
    let folders = [];

    for (let id of ids) {
        let folder = {
            index: id,
            contractId: happ.contractId,
            name: res[i++],
            size: parseInt(res[i++]),
        };
        let encodingString = res[i++];
        if (encodingString) {
            folder.encoding = BookEncoding.parse(encodingString);
        }

        folders.push(folder);
    }

    let drafts = FileEditor.drafts(happ.contractId);

    for (let draft of drafts) {
        if (!folders.find((it) => it.name === draft.folder)) {
            folders.push({
                contractId: happ.contractId,
                name: draft.folder,
                size: 0,
            });
        }
    }

    return folders;
}

/**
 *
 * @param {Happ} happ
 * @param {string} path
 * @param {boolean} loadContent
 * @return {Promise<BookFile[]>}
 */
export async function loadFiles(happ, path, loadContent = false) {
    let { size, encoding } = await getFolderEncoding(happ.web3, happ.address, path);

    if (size > 0) {
        let contract = new happ.web3.eth.Contract(abi, happ.address);
        let batcher = new Batcher(happ.web3)
            .setContract(contract)
            .add("getAccessToken", "accessToken", [path])
            .addObjectList("files", size, {
                name: {
                    method: "getFileName",
                    args: [path, true],
                },
                encoding: {
                    method: "getFileEncodingByIndex",
                    args: [path, true],
                    convert: (enc, file) =>
                        (file.encoding = enc ? BookEncoding.parse(enc) : encoding),
                },
                metadata: {
                    method: "getFileMetadataByIndex",
                    args: [path, true],
                },
            });

        let result = await batcher.execute();

        let files = result.files.map((it) => {
            return {
                ...it,
                index: it.id,
                accessToken: result.accessToken,
                path,
            };
        });

        if (loadContent) {
            let batcher = new Batcher(happ.web3)
                .setContract(contract)
                .addList("get", false, files.length, {
                    argsList: files.map((it) => [it.path, it.name]),
                });

            let result = await batcher.execute();
            let i = 0;
            for (let file of files) {
                file.data = result.get[i++];
            }
        }

        return files;
    }

    return [];
}

/**
 *
 * @param {Web3} web3
 * @param {address} address
 * @param {string} path Folder path
 * @param {string} file File name inside the folder
 * @return {Promise<BookFile>}
 */
export async function loadFile(web3, address, path, file) {
    let batcher = new Batcher(web3)
        .setContract(abi, address)
        .add("getAccessToken", "accessToken", [path])
        .add("getFileEncoding", "encoding", [path, file])
        .add("getFileMetadata", "metadata", [path, file])
        .add("get", "data", [path, file]);

    let result = await batcher.execute();

    let encoding;
    if (result.encoding) {
        encoding = BookEncoding.parse(result.encoding);
    } else {
        let res = await getFolderEncoding(web3, address, path);
        encoding = res.encoding;
    }

    return {
        name: file,
        metadata: result.metadata,
        encoding,
        data: result.data,
        path,
        accessToken: result.accessToken,
    };
}

/**
 * Load multiple files with contents in a batch. This function does NOT load metadata or encoding.
 *
 * @param {Web3} web3
 * @param {address} address
 * @param {{path: string, file: string}[]} list List of files to load
 * @return {Promise<BookFile[]>}
 */
export async function loadMultipleFiles(web3, address, list) {
    let batcher = new Batcher(web3).setContract(abi, address).addObjectList("files", list.length, {
        accessToken: {
            type: "string",
            method: "getAccessToken",
            argsList: list.map((it) => [it.path]),
        },
        data: {
            type: "string",
            method: "get",
            argsList: list.map((it) => [it.path, it.file]),
        },
    });

    let result = await batcher.execute();

    return result.files.map((it, index) => {
        return {
            name: list[index].file,
            path: list[index].path,
            data: it.data,
            accessToken: it.accessToken,
        };
    });
}

export async function getFolderEncoding(web3, address, path) {
    let pathParts = path.split("/");
    let part = pathParts.shift();
    let currentPath = [];
    let listOptions = {
        name: "encoding",
        argsList: [],
    };

    while (part) {
        currentPath.push(part);
        listOptions.argsList.push([currentPath.join("/")]);
        part = pathParts.shift();
    }

    let batcher = new Batcher(web3)

        .setContract(abi, address)
        .addInt("getFolderSize", "size", [path])
        .add("defaultEncoding")
        .addList("getEncoding", "encoding", undefined, listOptions);

    let result = await batcher.execute();

    let encoding = result.defaultEncoding;
    for (let it of result.encoding) {
        if (it) {
            encoding = it;
        }
    }

    console.log("Encoding is", encoding);

    return {
        size: result.size,
        encoding: BookEncoding.parse(encoding),
    };
}

export async function loadBookContent(network, address, path, file) {
    const web3 = Dapp.getNetwork(network, true).web3;
    // If it's null, no file is specified
    if (file !== null) {
        return [await loadFile(web3, address, path, file)];
    } else {
        let files = await loadFiles(
            {
                web3,
                address: address,
                network: getNetwork(network),
            },
            path,
            true
        );
        files.sort((a, b) => a.name.localeCompare(b.name));
        return files;
    }
}

export function nodePath(node, includeSelf = true) {
    let path = node.getPath(includeSelf);
    return path.slice(path.indexOf("/") + 1);
}
