import Vue from "vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import AddNftItem from "@/views/happs/lotto/AddNftItem.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import Recent from "@/lib/Recent";
import { mapGetters } from "vuex";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit.vue";
const recent = new Recent();
export default Vue.extend({
    name: "AddNft",
    components: { DumbappSubmit, DumbappPopover, AddNftItem, ProgressCircle, ContractLoader },
    props: {
        context: {
            type: Object,
        },
        pool: {
            type: Object,
        },
    },
    provide: {
        provider: null
    },
    data() {
        return {
            account: null,
            contract: null,
            loadingItems: false,
            items: [],
            dumbapp: null,
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        trackingId() {
            return ["add-nft", this.context.chainId, this.pool.pool].join("-");
        },
        api() {
            return this.$store.getters["user/api"];
        },
        nftLoader() {
            return this.$store.getters["user/nftLoader"];
        },
        nftDeps() {
            return {
                account: this.account,
                contractId: this.contract?.id,
            };
        },
        trackedItems() {
            let tracks = this.tracking[this.trackingId];
            return this.items.map((it) => {
                let tracking = tracks?.find((tr) => tr.tokenId === it.tokenId);
                return {
                    ...it,
                    adding: tracking?.status === "pending",
                    added: tracking?.status === "completed",
                };
            });
        },
    },
    watch: {
        nftDeps: {
            deep: true,
            handler(val) {
                if (val.account && val.contractId) {
                    this.loadingItems = true;
                    recent.addUserRecent("addnft", this.contract);
                    this.nftLoader
                        .getItems(this.$store.getters["user/batches"], this.contract, val.account)
                        .then((items) => {
                        this.items = items;
                        this.loadingItems = false;
                    });
                    this.api
                        .lottoAddNftDumbapp(val.contractId)
                        .then((it) => (this.dumbapp = it));
                }
                else {
                    this.items = [];
                }
            },
        },
    },
    methods: {
        contractSelected(contract) {
            this.contract = contract;
        },
        addSubmitted(submission) {
            let tokenId = submission.data.args[0].args[2];
            let payload = {
                key: this.trackingId,
                data: {
                    id: submission.id,
                    tokenId,
                },
            };
            this.$store.dispatch("dumbapp/trackSubmission", payload);
        },
        login() {
            this.$bus.emit("login_request", {});
        },
    },
    mounted() {
        let tracks = this.tracking?.[this.trackingId];
        if (tracks) {
            for (let track of tracks) {
                if (track.status !== "pending") {
                    this.$store.dispatch("dumbapp/removeTracking", {
                        key: this.trackingId,
                        id: track.id
                    });
                }
            }
        }
    },
});
