import BigNumber from "bignumber.js";
import oldAbi from "@/assets/data/CommunityToken.abi.json";
import { Batcher } from "@/lib/eth/Batcher";
import bookAbi from "@/assets/data/BaseBook.abi.json";
export async function getOldVotingData({ web3, contract, account, cache, firstLoad, }) {
    console.log(contract);
    const w3Contract = new web3.eth.Contract(oldAbi, contract.address);
    const count = parseInt(await w3Contract.methods.suggestionCount().call());
    let eventsPromise = w3Contract.getPastEvents("Votes", {
        fromBlock: contract.blockNumber,
        toBlock: contract.blockNumber + 2 * 24 * 60 * 60,
    });
    let batcher = new Batcher(web3)
        .setContract(w3Contract)
        .addBoolean("suggestionsRestricted")
        .addBoolean("requireBalanceForVote")
        .addBoolean("requireBalanceForCreateSuggestion")
        .addBoolean("oneVotePerAccount")
        .add("getBwQuill", "config", ["0x0000000000000000000000000000000000000002"])
        .addBig("voteCost")
        .addBigArray("getAllVotes")
        .addObjectList("suggestions", count, {
        suggestion: {
            type: "string",
            method: "getSuggestionText",
        },
        creator: {
            type: "string",
            method: "getSuggestionCreator",
        },
        ...(account
            ? {
                userVotes: {
                    type: "bignumber",
                    method: "getAccountVotes",
                    args: [account, true],
                },
            }
            : {}),
    });
    if (account) {
        batcher.addBig("balanceOf", "balance", [account]);
        batcher.addBig("votesLeft", "votesLeft", [account]);
    }
    let result = await batcher.execute();
    let suggestions = result.getAllVotes.map((it, index) => {
        let data = result.suggestions[index];
        return {
            id: index,
            votes: it,
            suggestion: data.suggestion,
            creator: data.creator,
            user: {
                votes: data.userVotes,
            },
        };
    });
    suggestions.sort((a, b) => b.votes.comparedTo(a.votes));
    let config = {};
    if (result.config && result.config !== "0x0000000000000000000000000000000000000000") {
        const bookContract = new web3.eth.Contract(bookAbi, result.config);
        let batcher = new Batcher(web3);
        batcher.setContract(bookContract);
        batcher
            .add("get", "language", ["settings", "language"])
            .add("get", "description", ["copy", "description"]);
        let res = await batcher.execute();
        try {
            config.language = JSON.parse(res.language);
        }
        catch (err) {
            console.error(err);
        }
        config.description = res.description;
    }
    let events = await eventsPromise.catch((err) => {
        console.error(err);
        return null;
    });
    let comments = [];
    if (events) {
        let index = 0;
        for (let event of events) {
            if (event.returnValues.comment) {
                comments.push({
                    index,
                    suggestionId: event.returnValues.suggestionId.toNumber(),
                    voter: event.returnValues.voter,
                    comment: event.returnValues.comment,
                    votes: new BigNumber(event.returnValues.votes.toString()),
                });
            }
            ++index;
        }
        for (let suggestion of suggestions) {
            suggestion.comments = comments.filter((it) => it.suggestionId === suggestion.id);
        }
    }
    let data = {
        suggestionsRestricted: result.suggestionsRestricted,
        requireBalanceForVote: result.requireBalanceForVote,
        requireBalanceForCreateSuggestion: result.requireBalanceForCreateSuggestion,
        oneVotePerAccount: result.oneVotePerAccount,
        voteCost: result.voteCost,
        suggestions,
        decimals: contract.parameters.decimals,
        contractData: contract,
        config,
    };
    if (account) {
        let userData = result;
        data.balance = userData.balance;
        data.votesLeft = userData.votesLeft;
    }
    data.votesLeft = (!data.votesLeft || data.votesLeft.isNaN()) ? null : data.votesLeft;
    return data;
}
