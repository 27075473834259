import abi from "@/assets/data/BalanceToken.abi.json";
import nftAbi from "@/assets/data/NfToken.abi.json";
import Dapp from "@/assets/lib/Dapp";
import { getNetwork } from "@/assets/lib/networks";
import Bwns from "@/lib/api/Bwns";
import { Batcher } from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import { preciseDiff } from "@/lib/moment-precise-range";
import { loadFiles } from "@/views/happs/book/lib/booklib";
import { LockData, LockUser, StakingData, StakingNft, TokenLock, } from "@/views/happs/locks/lockstypes";
import axios from "axios";
import BigNumber from "bignumber.js";
import mem from "mem";
import moment from "moment";
const bwns = new Bwns();
let stakingNs;
async function getStakingBook() {
    if (!stakingNs) {
        stakingNs = await bwns.resolve("staking", "book");
    }
    return stakingNs;
}
let stakingFilesPromise;
let stakingFiles;
async function getStakingFiles(contractAddress, net) {
    if (!stakingFilesPromise) {
        stakingFilesPromise = getStakingBook().then(async (it) => {
            let network = Dapp.getNetwork(it.network);
            let files = await loadFiles({ web3: network.web3, network, address: it.address }, "Rewards", true);
            return files.map((file) => parseStaking(file.data));
        });
    }
    if (!stakingFiles) {
        stakingFiles = await stakingFilesPromise;
    }
    return stakingFiles.find((staking) => staking &&
        staking.address.toLowerCase() === contractAddress.toLowerCase() &&
        staking.networkId === net.networkId);
}
export async function loadLocks(contractAddress, network, addresses) {
    if (!contractAddress) {
        return null;
    }
    let net = Dapp.getNetwork(network);
    let web3 = net.web3;
    let stakingPromise = getStakingFiles(contractAddress, net);
    let argsList = addresses.map((it) => [it]);
    let batcher = new Batcher(web3)
        .setContract(abi, contractAddress)
        .addInt("decimals")
        .addObjectList("data", addresses.length, {
        address: {
            valueList: addresses,
        },
        balance: {
            method: "balanceOf",
            argsList,
            type: "bignumber",
        },
        availableBalance: {
            method: "availableBalanceOf",
            argsList,
            type: "bignumber",
        },
        unlocked: {
            method: "unlockedBalanceOf",
            argsList,
            type: "bignumber",
        },
        locks: {
            method: "locksOf",
            argsList,
            type: "bignumber",
        },
        nativeStaked: {
            method: "stakeOf",
            argsList,
            type: "bignumber",
        },
    });
    let result = await batcher.execute();
    let staking;
    try {
        staking = await stakingPromise;
        if (staking) {
            let addDays = 7;
            if (staking.period === "daily") {
                addDays = 1;
            }
            if (staking.time && staking.time.isBefore()) {
                staking.time.add(addDays, "days");
            }
        }
    }
    catch (err) {
        console.error(err);
    }
    let data = result.data.map((it) => {
        let lockData = parseLocks(it.locks, staking?.time);
        let user = new LockUser(it.address, it.balance, it.availableBalance, it.unlocked, lockData);
        if (lockData.futureSoftTotal) {
            user.futureSoftTotal = lockData.futureSoftTotal;
        }
        if (!user.availableBalance || user.availableBalance.isNaN()) {
            user.availableBalance = user.balance;
        }
        return user;
    });
    if (staking?.nft) {
        let baseRate = new BigNumber(0.0777);
        let nfts = await loadNfts(staking.nft, staking.nftNetworkId, addresses, baseRate);
        for (let user of data) {
            let tokens = nfts[user.address];
            if (tokens) {
                user.stakingRate = tokens[0].rate;
                user.nfts = tokens;
            }
            else {
                user.stakingRate = baseRate;
            }
        }
    }
    for (let it of data) {
        it.nextReward = calculateNextReward(staking, it);
    }
    return { data, staking };
}
function calculateNextReward(staking, user) {
    if (!staking) {
        return new BigNumber(0);
    }
    let periodRate = user.stakingRate || staking.reward;
    if (staking.period === "daily") {
        periodRate = periodRate.div(365);
    }
    else {
        periodRate = periodRate.div(52);
    }
    let balance;
    switch (staking.uses) {
        case "balance":
            balance = user.balance;
            break;
        case "locked":
            balance = user.lockedTotal;
            break;
        default:
            balance = user.futureSoftTotal;
            break;
    }
    if (balance?.gt(0)) {
        return periodRate.times(balance);
    }
    return new BigNumber(0);
}
export function parseLocks(ints, futureBalanceAt = null) {
    let withSplitting = true;
    if (ints.length === 0) {
        return new LockData([], new BigNumber(0), new BigNumber(0));
    }
    else if (ints.length === 2) {
        withSplitting = false;
    }
    else {
        // If the fourth element is higher than this, it's a timestamp, and the lock format is old
        if (ints[3].gt(65535)) {
            withSplitting = false;
        }
    }
    let futureSoftTotal = new BigNumber(0);
    let locks = [];
    let i = 0;
    while (i < ints.length) {
        let lock = new TokenLock(ints[i++], moment.unix(ints[i++].toNumber()));
        if (withSplitting) {
            lock.periodLength = parseInt(ints[i++].toString(10));
            lock.periods = parseInt(ints[i++].toString(10));
        }
        if (!lock.periods) {
            if (lock.expiration.isBefore()) {
                lock.softLock = lock.value;
                lock.value = new BigNumber(0);
            }
        }
        else {
            if (futureBalanceAt) {
                let futureLock = tokenLockAt(lock, futureBalanceAt);
                futureSoftTotal = futureSoftTotal.plus(futureLock.softLock);
            }
            lock = tokenLockAt(lock, moment());
        }
        locks.push(lock);
    }
    locks.sort((a, b) => {
        if (a.expiration === null && b.expiration === null) {
            return 0;
        }
        if (a.expiration === null) {
            return 1;
        }
        if (b.expiration === null) {
            return -1;
        }
        return a.expiration.unix() - b.expiration.unix();
    });
    let data = new LockData(locks, locks.reduce((acc, val) => acc.plus(val.value), new BigNumber(0)), locks.reduce((acc, val) => acc.plus(val.softLock), new BigNumber(0)));
    if (locks.length > 0) {
        data.nextUnlock = {
            timestamp: locks[0].expiration,
            value: locks[0].partial || locks[0].value,
        };
    }
    if (futureBalanceAt) {
        data.futureSoftTotal = futureSoftTotal;
    }
    return data;
}
export function tokenLockAt(lock, timestamp) {
    let periodLength = lock.periodLength;
    let expiration = lock.expiration;
    let diff = Math.max(timestamp.unix() - expiration.unix(), 0);
    // ceil because we're comparing against the expiration, not the start of the current period
    let periods = Math.ceil(diff / periodLength);
    // if (periods > (2**16)) {
    //     expiration = expiration.add(periodLength * 2**16, "seconds");
    //     diff = Math.max(timestamp.unix() - expiration.unix(), 0);
    //     periods = Math.ceil(diff / periodLength);
    // }
    let data = new TokenLock();
    // Adjust based on periods that have expired
    if (periods < lock.periods) {
        data.partial = lock.value.div(lock.periods);
        data.value = lock.value.minus(lock.value.div(lock.periods).times(periods));
        data.expiration = lock.expiration.clone().add(lock.periodLength * periods, "seconds");
        data.periods = lock.periods - periods;
        data.fullExpiration = lock.expiration
            .clone()
            .add(periodLength * (lock.periods - 1), "seconds");
        data.periodLength = lock.periodLength;
        data.periodLengthFormatted = preciseDiff(moment(), moment().add(lock.periodLength, "seconds"));
    }
    else {
        // It's all unlocked and only "soft" locked now
        data.value = new BigNumber(0);
        data.expiration = null;
    }
    data.softLock = lock.value.minus(data.value);
    return data;
}
export function sendUnlock(provider, account, contractAddress) {
    return Chain.writeContract({ ext: provider, from: account }, contractAddress, abi, "unlock");
}
function parseStaking(content) {
    let parsed = content.split("\n").map((it) => it.replace(/^[^:]*:\s*/, ""));
    let net = getNetwork(parsed[1]);
    let data = new StakingData(net.networkId, parsed[0].toLowerCase());
    if (parsed[2].startsWith("0x")) {
        let nft = parsed[2].split(" ");
        data.nft = nft[0];
        if (nft[1]) {
            data.nftNetworkId = getNetwork(nft[1]).networkId;
        }
        else {
            data.nftNetworkId = 1;
        }
        if (nft[2]) {
            data.reward = new BigNumber(nft[2].replace("%", "")).div(100);
        }
        else {
            data.reward = new BigNumber(0.0777);
        }
    }
    else {
        data.reward = new BigNumber(parsed[2].replace("%", "")).div(100);
    }
    if (parsed[3]?.startsWith("no")) {
        data.compounding = false;
    }
    if (parsed[4]) {
        if (parsed[4].includes(" ")) {
            data.period = "weekly";
            data.time = moment.utc(parsed[4], ["ddd H:mm", "dddd H:mm"]).local();
        }
        else {
            data.period = "daily";
            data.time = moment.utc(parsed[4], ["H:mm"]).local();
        }
    }
    if (parsed[5]) {
        data.uses = parsed[5];
    }
    return data;
}
async function _loadNfts(contractAddress, network, addresses, baseRate) {
    let net = Dapp.getNetwork(network);
    let web3 = net.web3;
    let argsList = addresses.map((it) => [it]);
    let batcher = new Batcher(web3)
        .setContract(nftAbi, contractAddress)
        .add("baseUri")
        .addList("balanceOf", false, addresses.length, {
        argsList,
    }, "int");
    let res = await batcher.execute();
    let baseUri = res.baseUri;
    let balances = res.balanceOf;
    if (!baseUri) {
        return {};
    }
    argsList = [];
    let i = 0;
    let owners = {};
    for (let address of addresses) {
        let balance = balances[i];
        if (balance > 0) {
            owners[address] = [];
            for (let j = 0; j < balance; j++) {
                argsList.push([address, j.toString()]);
            }
        }
        ++i;
    }
    if (argsList.length === 0) {
        return {};
    }
    let batcher2 = new Batcher(web3)
        .setContract(nftAbi, contractAddress)
        .addList("tokenOfOwnerByIndex", false, argsList.length, {
        argsList,
    }, "int");
    let res2 = await batcher2.execute();
    i = 0;
    for (let it of res2.tokenOfOwnerByIndex) {
        let address = argsList[i][0];
        owners[address].push(it);
        ++i;
    }
    let data = {};
    for (let [address, val] of Object.entries(owners)) {
        let tokens = [];
        for (let tokenId of val) {
            let token = new StakingNft((await axios.get(baseUri + tokenId)).data);
            token.id = tokenId;
            if (token.attributes) {
                for (let it of token.attributes) {
                    let match = /(\d+(\.\d+)?)% APY Fixed/.exec(it.trait_type);
                    if (match && match[1]) {
                        token.rate = new BigNumber(match[1]).div(100);
                        token.effect = `${it.trait_type} (${it.value})`;
                        break;
                    }
                    else if (it.trait_type === "APY booster") {
                        token.rate = new BigNumber(baseRate).plus(new BigNumber(it.value).div(100));
                        token.effect = `${it.value}% APR Boost`;
                        break;
                    }
                }
            }
            if (!token.rate) {
                token.rate = new BigNumber(baseRate);
            }
            if (net.networkId === 1) {
                token.link = `https://opensea.io/assets/${contractAddress}/${token.id}`;
            }
            else if (net.networkId === 4) {
                token.link = `https://testnet.opensea.io/assets/${contractAddress}/${token.id}`;
            }
            tokens.push(token);
        }
        tokens.sort((a, b) => b.rate.minus(a.rate).toNumber());
        data[address] = tokens;
    }
    return data;
}
export const loadNfts = mem(_loadNfts, {
    cacheKey: JSON.stringify,
    maxAge: 60000,
});
