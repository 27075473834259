import Vue from "vue";
import { getNetwork } from "@/assets/lib/networks";
/**
 * Loads NFT contract info and NFT item metadata based on `uri` and `nftId`.
 */
export default Vue.extend({
    props: {
        nftAddress: String,
        nftContract: {
            type: Object
        },
        chainId: Number,
        uri: String,
        tokenId: String
    },
    data() {
        return {
            metadata: null,
            loadedContract: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        loader() {
            return this.$store.getters["user/metadataLoader"];
        },
        nftId() {
            return this.chainId + "." + this.nftAddress;
        },
        explorerLink() {
            return getNetwork(this.chainId).explorerNft(this.nftAddress, this.tokenId);
        },
        name() {
            if (!this.metadata) {
                return "...";
            }
            else if (this.metadata.name) {
                return this.metadata.name;
            }
            return "N/A";
        },
        render() {
            let collection = this.nft?.name || "...";
            let name;
            if (!this.metadata) {
                name = "...";
            }
            else {
                name = this.metadata.name || collection + " #" + this.tokenId;
            }
            return {
                image: this.metadata?.image,
                name,
                collection,
            };
        },
        nft() {
            return this.nftContract || this.loadedContract;
        }
    },
    watch: {
        uri: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loader.loadMetadata(val).then((it) => (this.metadata = it));
                }
                else {
                    this.metadata = {};
                }
            },
        },
        nftId: {
            immediate: true,
            handler(val) {
                if (this.nftContract) {
                    return;
                }
                if (val) {
                    this.api.contracts.getContract(val).then((it) => (this.loadedContract = it));
                }
                else {
                    this.loadedContract = null;
                }
            },
        },
    },
});
