import { addressEqual, getChain, toChainId } from "@blockwell/chains";
import { PrimeSwapSettings } from "@blockwell/book/lib/prime";
import abi from "@/assets/data/SwapNftRelay.abi.json";
import { Chain } from "@blockwell/chain-client";
export async function getNftSwapData({ contract, bookHelper }) {
    let allSettings = await bookHelper.getNftSettings(toChainId(contract.network), contract.address, {
        swapContracts: PrimeSwapSettings.swapContracts,
    });
    return {
        contracts: allSettings.swapContracts.settings
    };
}
export async function getPendingSwaps(api, account) {
    let swaps = await api.pendingSwaps(account);
    let chains = [];
    swaps = swaps.filter(it => getChain(it.chainId).chainId !== 0 && getChain(it.toChainId).chainId !== 0);
    if (swaps.length > 0) {
        for (let swap of swaps) {
            let chain = chains.find(it => it.chainId === swap.toChainId);
            if (!chain) {
                chain = { chainId: swap.toChainId, swaps: [] };
                chains.push(chain);
            }
            chain.swaps.push(swap);
        }
        for (let chain of chains) {
            let contracts = [];
            for (let swap of chain.swaps) {
                let contr = contracts.find(it => addressEqual(it.address, swap.contractAddress));
                if (!contr) {
                    contr = { address: swap.contractAddress, swapIds: [] };
                    contracts.push(contr);
                }
                contr.swapIds.push(swap.swapId);
            }
            for (let contr of contracts) {
                console.log("readArray", chain, contr, abi, contr.swapIds);
                let done = await Chain.readArray(chain.chainId, contr.address, abi, "swapsDone", [contr.swapIds]);
                let doneSwaps = [];
                for (let i = 0; i < contr.swapIds.length; i++) {
                    if (done[i]) {
                        doneSwaps.push(contr.swapIds[i]);
                    }
                }
                swaps = swaps.filter(it => !doneSwaps.includes(it.swapId));
            }
        }
    }
    return swaps;
}
