import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import BigNumber from "bignumber.js";
import Modal from "@/components/Modal.vue";
import NumberInput from "@/components/dumbapp/input/NumberInput.vue";
import LottoProfit from "@/views/happs/lotto/LottoProfit.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
export default Vue.extend({
    name: "ConfigureWinRates",
    components: {
        DumbappPopover,
        LottoProfit,
        NumberInput,
        Modal,
        DumbappModal,
        TokenContract,
        TokenAmount,
        Info,
        InfoField,
        InfoSection,
    },
    props: {
        context: {
            type: Object,
        },
    },
    data() {
        return {
            form: {
                rates: [],
            },
            priceField: "",
        };
    },
    computed: {
        decimals() {
            return this.context?.token?.parameters?.decimals || "18";
        },
        tokenName() {
            return this.context?.token?.name || "tokens";
        },
        totalWinRate() {
            return this.rawReturns.reduce((acc, it) => {
                return acc.plus(it.chance);
            }, new BigNumber(0));
        },
        rawPrice() {
            let value = new BigNumber(this.priceField);
            if (!value.isNaN()) {
                return value.times(`1e${this.decimals}`);
            }
            return null;
        },
        rawReturns() {
            return this.form.rates.map((it) => {
                return {
                    chance: new BigNumber(it.chance).times(100),
                    amount: new BigNumber(it.amount).times(`1e${this.decimals}`),
                };
            });
        },
        winRatesQuery() {
            const rates = this.form.rates;
            return {
                chances: rates.map((it) => it.chance),
                amounts: rates.map((it) => it.amount),
            };
        },
        priceQuery() {
            if (this.rawPrice && !this.rawPrice.eq(this.context.data.price)) {
                return {
                    newPrice: this.rawPrice.div(`1e${this.decimals}`).toString(10),
                };
            }
            return null;
        },
    },
    watch: {
        "context.data.returns": {
            deep: true,
            immediate: true,
            handler(val, old) {
                // Only replace the form if there's not a previous value
                if (val && !old) {
                    this.form.rates = val.map((it) => {
                        return {
                            chance: it.chance.div(100).toString(10),
                            amount: it.amount.div(`1e${this.decimals}`).toString(10),
                        };
                    });
                }
            },
        },
        "context.data.price": {
            immediate: true,
            handler(val) {
                if (val && !this.priceField) {
                    this.priceField = val.div(`1e${this.decimals}`).toString(10);
                }
            },
        },
    },
    methods: {},
});
