import Vue from "vue";
import { every } from "@/lib/interval";
import PendingSwap from "@/views/happs/nftswap/PendingSwap.vue";
import { getPendingSwaps } from "@/views/happs/nftswap/nft-swap";
export default Vue.extend({
    name: "PendingSwapsList",
    components: { PendingSwap },
    props: {
        account: String,
        hideEmpty: Boolean
    },
    data() {
        return {
            interval: null,
            pending: []
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
    },
    watch: {
        account: {
            immediate: true,
            handler(val, old) {
                if (!val) {
                    this.interval?.clear();
                }
                else if (val !== old) {
                    this.interval?.clear();
                    this.interval = every(async () => {
                        if (val) {
                            this.pending = await getPendingSwaps(this.api, val);
                        }
                        else {
                            this.pending = [];
                        }
                    }, 5000);
                }
            },
        },
    },
    beforeDestroy() {
        this.interval?.clear();
    },
});
