<template>
    <div class="nft-grid">
        <NfToken v-for="token in tokens"
                 :token="token"
                 :nft-data="nftData"
                 :dumbapps="dumbapps"
                 :key="token.tokenId.toString()" />
    </div>
</template>

<script>
import { NftData } from "@/views/happs/nft/lib/nftlib";
import NfToken from "@/views/happs/nft/NfToken";

export default {
    name: "NftGrid",
    components: { NfToken },
    props: {
        nftData: NftData,
        dumbapps: Object,
        tokens: Array
    },
    data() {
        return {};
    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.nft-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    justify-content: space-between;
    margin: 30px 0;

    @media screen and (max-width: 720px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 480px) {
        font-size: 0.8em;
    }
    @media screen and (max-width: 350px) {
        grid-template-columns: 1fr;
        font-size: 1em;
    }
}
</style>
