import Vue from "vue";
import NftItem from "@/components/NftItem.vue";
import { Dumbapp } from "@blockwell/dumbapp";
import DumbappPopover from "@/components/DumbappPopover.vue";
export default Vue.extend({
    name: "NftItemPoolDrop",
    components: { DumbappPopover, NftItem },
    props: {
        item: {
            type: Object,
        },
        dumbapp: Dumbapp,
    },
    data() {
        return {};
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
    },
});
