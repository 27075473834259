import Vue from "vue";
import { getChain, toChainId } from "@blockwell/chains";
import NftSwapItem from "@/views/happs/nftswap/NftSwapItem.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import { mapGetters } from "vuex";
import ProgressCircle from "@/components/ProgressCircle.vue";
export default Vue.extend({
    name: "NftSwapList",
    components: { ProgressCircle, DumbappPopover, NftSwapItem },
    props: {
        contract: {
            type: Object,
        },
        swapContract: {
            type: Object,
        },
        account: String,
        toChain: {
            type: Object,
        },
    },
    data() {
        return {
            items: [],
            swapForm: {},
            dumbapp: null,
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        api() {
            return this.$store.getters["user/api"];
        },
        nftLoader() {
            return this.$store.getters["user/nftLoader"];
        },
        itemsDeps() {
            return {
                account: this.account,
                swapContract: this.swapContract,
            };
        },
        network() {
            return getChain(this.swapContract?.network);
        },
        swapTokens() {
            return Object.entries(this.swapForm)
                .map(([key, val]) => (val ? key.split("-")[1] : null))
                .filter((it) => !!it);
        },
        trackingId() {
            return ["swap", this.swapContract?.network, this.swapContract?.address].join("-");
        },
        tracked() {
            let tracks = this.tracking[this.trackingId];
            if (tracks) {
                return tracks.filter((it) => it.status === "pending").flatMap((it) => it.tokenIds);
            }
            return [];
        },
        dumbappValues() {
            return {
                chainId: this.network.chainId,
                toChainId: toChainId(this.toChain?.network),
                tokenContract: this.swapContract.address,
                tokenIds: this.swapTokens,
            };
        },
    },
    watch: {
        itemsDeps: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val.account || !val.swapContract) {
                    this.items = [];
                    return;
                }
                this.getItems(val.account);
            },
        },
    },
    methods: {
        addSubmitted(submission) {
            let tokenIds = submission.data.args[0].args[3];
            let payload = {
                key: this.trackingId,
                data: {
                    id: submission.id,
                    tokenIds,
                },
            };
            this.$store.dispatch("dumbapp/trackSubmission", payload);
        },
        getItems(account, refresh = false) {
            let batches = this.$store.getters["user/batches"];
            this.nftLoader.getItems(batches, this.contract, account, refresh).then((items) => {
                this.items = items;
            });
        },
        dumbappCompleted(submission) {
            if (["signed-strict-nft", "swap-nft"].includes(submission.dumbapp.uniqueReference)) {
                setTimeout(() => {
                    this.getItems(this.account, true);
                }, 1000);
            }
        },
    },
    created() {
        let api = this.api;
        api.swapNftDumbapp().then((it) => (this.dumbapp = it));
    },
    mounted() {
        this.$bus.on("dumbapp-completed", this.dumbappCompleted);
        let tracks = this.tracking?.[this.trackingId];
        if (tracks) {
            for (let track of tracks) {
                if (track.status !== "pending") {
                    this.$store.dispatch("dumbapp/removeTracking", {
                        key: this.trackingId,
                        id: track.id,
                    });
                }
            }
        }
    },
    beforeDestroy() {
        this.$bus.off("dumbapp-completed", this.dumbappCompleted);
    },
});
