import abi from "@/assets/data/BaseBook.abi.json";
import * as R from 'rambdax';

export default class FileEditor {
    static drafts(contractId) {
        let key = `fileeditor.${contractId}`;
        return Object.keys(localStorage)
            .filter(it => it.startsWith(key) && !it.endsWith('.encoded'))
            .map(it => {
                let parts = it.split('.');
                if (parts.length > 4) {
                    let newparts = [];
                    let current = "";
                    for (let part of parts) {
                        if (part.endsWith('\\')) {
                            current += part.slice(0, -1) + ".";
                        } else {
                            current += part;
                            newparts.push(current);
                            current = "";
                        }
                    }
                    parts = newparts;
                }

                return {
                    originalKey: it,
                    folder: parts[2],
                    file: parts[3],
                    path: parts[2] + "/" + parts[3]
                }
            });
    }

    /**
     *
     * @param {Happ} happ
     * @param {string} folder
     * @param {string} file
     * @param {BookEncoding} encoding
     */
    constructor(happ, folder, file, encoding) {
        /**
         * @type {Happ}
         */
        this.happ = happ;
        this.folder = folder;
        this.file = file;
        this.storageKey = this.makeStorageKey();
        this.encodedStorageKey = this.storageKey + '.encoded';
        this.encoding = encoding;
        this.encoded = false;
    }

    makeStorageKey() {
        let folder = this.folder.replace('.', '\\.');
        let file = this.file.replace('.', '\\.');
        return `fileeditor.${this.happ.contractId}.${folder}.${file}`;
    }

    async loadFileContent() {
        let contract = new this.happ.web3.eth.Contract(abi, this.happ.address);
        try {
            this.originalEncoded = await contract.methods.get(this.folder, this.file).call();
        } catch (err) {
            console.warn("Could not get file contents", err.message);
        }

        if (this.encoding.isEncrypted()) {
            if (this.originalEncoded) {
                this.encoded = true;
            }
            try {
                // If the encrypted contents are identical, no new changes have been drafted
                this.encodedDraft = localStorage.getItem(this.encodedStorageKey);
                if (this.encodedDraft) {
                    if (this.encodedDraft !== this.originalEncoded) {
                        this.draft = localStorage.getItem(this.storageKey);
                    } else {
                        this.clearDraft();
                    }
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            this.original = this.originalEncoded;
            this.draft = localStorage.getItem(this.storageKey);

            // Old draft that now matches the original, clear it
            if (this.draft && this.draft === this.original) {
                this.clearDraft();
            }
        }
        console.log('loadFileContent', R.clone({
            draft: this.draft,
            encodedDraft: this.encodedDraft,
            original: this.original,
            originalEncoded: this.originalEncoded
        }));

        return {
            originalEncoded: this.originalEncoded,
            original: this.original,
            draft: this.draft
        }
    }

    saveDraft(content) {
        if (content && this.original === content) {
            console.log('content matches original, clearing draft');
            this.clearDraft();
            return null;
        } else {
            this.draft = content;
            localStorage.setItem(this.storageKey, content);
            return this.draft;
        }
    }

    saveEncodedDraft(content) {
        this.encodedDraft = content;
        localStorage.setItem(this.encodedStorageKey, content);
    }

    clearDraft() {
        this.draft = null;
        this.encodedDraft = null;
        localStorage.removeItem(this.storageKey);
        localStorage.removeItem(this.encodedStorageKey);
    }

    hasChanges() {
        if (this.encoding.isEncrypted()) {
            return this.draft && this.draft !== this.originalEncoded;
        } else {
            return this.draft && this.draft !== this.original;
        }
    }

    path() {
        return this.folder + "/" + this.file;
    }
}
