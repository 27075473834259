import {AesCrypt} from "@/views/happs/book/encryption/AesCrypt";
import {FastAesCrypt} from "@/views/happs/core/lib/FastAesCrypt";

/**
 * @type {AesCrypt}
 */
let crypt;

if (window.crypto?.subtle) {
    crypt = new FastAesCrypt();
} else {
    crypt = new AesCrypt();
}

export {crypt};

export default {
    data() {
        return {
            crypt
        }
    }
}
