import Vue from "vue";
import { getNetwork } from "@/assets/lib/networks";
import TokenAmount from "@/components/TokenAmount.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import NftItem from "@/components/NftItem.vue";
export default Vue.extend({
    name: "MarketItem",
    components: { NftItem, DumbappPopover, TokenAmount },
    props: {
        listing: {
            type: Object,
        },
        market: {
            type: Object
        }
    },
    data() {
        return {
            token: null,
            dumbapp: null
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        decimals() {
            return this.token?.parameters?.decimals || 18;
        },
        symbol() {
            return this.token?.parameters?.symbol;
        },
        chainId() {
            return getNetwork(this.listing.network).chainId;
        },
        value() {
            if (this.token) {
                return this.listing.value.div(`1e${this.decimals}`);
            }
            return null;
        },
        renderValue() {
            return this.value?.toFormat() || "...";
        },
        dumbappDeps() {
            return {
                contract: this.market?.id,
                token: this.token?.id
            };
        },
        query() {
            return {
                value: this.value?.toString(10),
                listingId: this.listing.listingId.toString(10)
            };
        }
    },
    watch: {
        dumbappDeps: {
            immediate: true,
            handler(val) {
                if (val.contract && val.token) {
                    this.api.marketDumbapp(val.contract, val.token)
                        .then((it) => this.dumbapp = it);
                }
                else {
                    this.dumbapp = null;
                }
            }
        },
        "listing.paymentToken": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.api.contracts
                        .getContract({
                        address: val,
                        chainId: getNetwork(this.listing.network).chainId,
                    })
                        .then((it) => (this.token = it));
                }
                else {
                    this.token = null;
                }
            },
        },
    },
});
