import Vue from "vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import ContractDropdown from "@/views/happs/components/ContractDropdown.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import HappMixin from "@/views/happs/Happ";
import { getNftSwapData } from "@/views/happs/nftswap/nft-swap";
import { addressEqual, chainEqual } from "@blockwell/chains";
import NftSwapList from "@/views/happs/nftswap/NftSwapList.vue";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit.vue";
import PendingSwapsList from "@/views/happs/nftswap/PendingSwapsList.vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import NftSwapAccount from "@/views/happs/nftswap/NftSwapAccount.vue";
export default Vue.extend({
    name: "NftSwap",
    components: {
        NftSwapAccount,
        DumbappWallet,
        PendingSwapsList,
        DumbappSubmit,
        NftSwapList,
        ContractLoader,
        ProgressCircle,
        ContractDropdown,
        StyleableLayout,
    },
    mixins: [HappMixin],
    provide() {
        return {
            provider: null
        };
    },
    data() {
        let loaderFunction = getNftSwapData;
        return {
            selectedAccount: null,
            walletType: "metamask",
            type: "nft-swap",
            errorMessage: null,
            swapData: null,
            contracts: null,
            dumbapps: {},
            loaderFunction,
            loader: null,
        };
    },
    computed: {
        contractLoaded() {
            return !!this.swapData?.contracts;
        },
        nftName() {
            return this.contracts?.[0]?.name || "NFT Swap";
        },
        contractQueries() {
            return this.swapData?.contracts?.map((it) => {
                return {
                    chainId: it.network,
                    address: it.address,
                };
            });
        },
        settings() {
            if (this.$route.path.toLowerCase() === "/nft-swap/striptest" ||
                this.$route.path.toLowerCase() === "/nft-swap/stripperville") {
                return {
                    heading: true,
                    title: false,
                    whitelabelLayout: true,
                    logo: "https://stripperville.io/wp-content/uploads/2022/04/logo.ae3ff315.png",
                    icon: "https://lh3.googleusercontent.com/W9fSmIlzwOESNc1pqlyN6OzSwv-L47ouE83-_1i_tqpc0NGCamLvUofc6b2HdIQLXLedrDiCLOeiQXHZsRlFt1qv600z-7sKsoQ0-QA=s168",
                    mainButtonOutline: false,
                    secondaryButtonOutline: true,
                    style: {
                        "--whitelabeled-heading": "#52102A",
                        "--large-heading-background": "#000000",
                        "--wallet-heading-background": "#52102A",
                        "--link": "#FC157D",
                        "--link-hover": "#FF4998",
                        "--label-text": "#FFFFFF",
                        "--body-text": "#D4D2D2",
                        "--body": "#000000",
                        "--primary": "#FC157D",
                        "--primary-muted": "#52102A",
                        "--primary-highlight": "#FF4998",
                        "--grey": "#202020",
                        "--font-family-body-text": "Roboto",
                        "--font-family-button": "",
                        "--font-family-heading": "Roboto",
                        "--primary-button": "#FC157D",
                        "--primary-button-text": "#FFFFFF",
                        "--info-heading-background": "#000000",
                        "--info-heading-bottom-border": "#FC157D",
                        "--info-heading-text-color": "#FFFFFF",
                    },
                    logoLarge: true,
                    iconLink: "https://stripperville.io",
                };
            }
            return null;
        },
    },
    watch: {
        contractQueries: {
            immediate: true,
            deep: true,
            handler(queries) {
                if (!queries) {
                    this.contracts = null;
                    return;
                }
                let reload = false;
                if (!this.contracts) {
                    reload = true;
                }
                else if (this.contracts.length !== queries.length) {
                    reload = true;
                }
                else {
                    let i = 0;
                    for (let contract of this.contracts) {
                        if (!chainEqual(contract.network, queries[i]?.chainId) ||
                            !addressEqual(contract.address, queries[i]?.address)) {
                            reload = true;
                            break;
                        }
                    }
                }
                if (reload) {
                    this.api.contracts.getAll(queries).then((contracts) => {
                        this.contracts = contracts;
                    });
                }
            },
        },
    },
    methods: {
        reset() {
            this.$router.push("/nft-swap");
        },
        onData(data) {
            if (data) {
                this.swapData = data;
            }
            else {
                this.swapData = null;
                this.dumbapps = {};
            }
        },
        toChain(contract) {
            return this.swapData?.contracts?.filter((it) => !chainEqual(it.network, contract.network))?.[0];
        },
        dumbappCompleted(submission) {
            if (["signed-strict-nft", "swap-nft"].includes(submission.dumbapp.uniqueReference)) {
                setTimeout(() => {
                    this.loader?.startUpdates();
                }, 1000);
            }
        },
    },
    mounted() {
        this.$bus.on("dumbapp-completed", this.dumbappCompleted);
    },
    beforeDestroy() {
        this.$bus.off("dumbapp-completed", this.dumbappCompleted);
    },
});
