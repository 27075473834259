import Vue from "vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import HappMixin from "@/views/happs/Happ";
import { getListingData, getMarketData, } from "@/views/happs/market/market-lib";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import ContractDropdown from "@/views/happs/components/ContractDropdown.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import MarketList from "@/views/happs/market/MarketList.vue";
import Modal from "@/components/Modal.vue";
import AddNftItem from "@/views/happs/lotto/AddNftItem.vue";
import AddNft from "@/views/happs/lotto/AddNft.vue";
import AddListing from "@/views/happs/market/AddListing.vue";
export default Vue.extend({
    name: "Market",
    components: { AddListing, AddNft, AddNftItem, Modal, MarketList, ProgressCircle, ContractDropdown, ContractLoader, StyleableLayout },
    mixins: [HappMixin],
    data() {
        let loaderFunction;
        loaderFunction = getMarketData;
        return {
            type: "market",
            errorMessage: null,
            marketData: null,
            metaListings: [],
            dumbapps: {},
            loaderFunction,
            loader: null,
            showAddListing: false
        };
    },
    computed: {
        contractLoaded() {
            return !!this.marketData?.contractData?.address;
        },
        marketName() {
            return this.marketData?.contractData?.name || "Market";
        },
    },
    watch: {
        "marketData.listings": {
            deep: true,
            immediate: true,
            handler(val) {
                if (val && val.length > 0) {
                    getListingData(this.happ.web3, this.marketData).then((it) => (this.metaListings = it));
                }
                else {
                    this.metaListings = null;
                }
            },
        },
    },
    methods: {
        reset() {
            this.$router.push("/market");
            this.interval?.clear();
            this.interval = null;
        },
        onData(data) {
            if (data) {
                this.marketData = data;
                document.title = `${data.contractData.name} | Blockwell Wallet`;
            }
            else {
                this.marketData = null;
                this.dumbapps = {};
                document.title = "Market | Blockwell Wallet";
            }
        },
    },
});
