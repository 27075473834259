import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import BigNumber from "bignumber.js";
export default Vue.extend({
    name: "LottoProfit",
    components: { DumbappModal, TokenContract, TokenAmount, Info, InfoField, InfoSection },
    props: {
        context: {
            type: Object,
        },
        returns: {
            type: Array,
        },
        price: {
            type: BigNumber
        }
    },
    data() {
        return {};
    },
    computed: {
        usePrice() {
            return this.price || this.context?.data?.price;
        },
        averageWin() {
            const returns = this.returns || this.context?.data?.returns || [];
            return returns.reduce((acc, it) => {
                return acc.plus(it.chance.div(10000).times(it.amount));
            }, new BigNumber(0));
        },
        profit() {
            return this.usePrice?.minus(this.averageWin);
        },
        edge() {
            return new BigNumber(100).minus(this.averageWin.div(this.usePrice).times(100)).sd(3);
        }
    },
    methods: {},
});
