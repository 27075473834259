import moment from "moment";
import { Batcher } from "@/lib/eth/Batcher";
import nftAbi from "@/assets/data/NfToken.abi.json";
import { BatchRequest } from "web3-providers";
import { sort } from "rambdax";
export async function getMarketData({ web3, contract, account, cache, firstLoad }) {
    let batcher = new Batcher(web3)
        .setContract(contract.abi, contract.address)
        .addStructArray("getListings", "listings");
    let res = await batcher.execute();
    let data = {
        listersOnly: false,
        listings: sort((a, b) => b.listingId.comparedTo(a.listingId), res.listings.map(it => {
            return {
                ...it,
                timestamp: moment.unix(it.timestamp.toNumber()),
                tokenId: it.tokenId.toString(10)
            };
        })),
        contractData: contract
    };
    return data;
}
export async function getListingData(web3, data) {
    let listings = [...data.listings];
    listings.sort((a, b) => a.nft.localeCompare(b.nft));
    let address = undefined;
    let contract = undefined;
    let batch = new BatchRequest(web3);
    for (let listing of listings) {
        if (address !== listing.nft) {
            address = listing.nft;
            contract = new web3.eth.Contract(nftAbi, address);
        }
        batch.add(contract.methods.tokenURI(listing.tokenId).call.request());
    }
    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    }
    catch (err) {
        console.log('Caught error in batch calling', err.message);
        res = err.response.map((it) => it.jsonrpc ? it.result : it);
    }
    return data.listings.map(listing => {
        let index = listings.findIndex(it => it.listingId.eq(listing.listingId));
        return {
            ...listing,
            uri: res[index],
            network: data.contractData.network
        };
    });
}
