import Vue from "vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import HappMixin from "@/views/happs/Happ";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import ContractDropdown from "@/views/happs/components/ContractDropdown.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { getItemData, getLottoData } from "@/views/happs/lotto/lotto-lib";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import { getNetwork } from "@/assets/lib/networks";
import { addressEqual } from "@/lib/eth/Contract";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import LottoPayment from "@/views/happs/lotto/LottoPayment.vue";
import LottoStats from "@/views/happs/lotto/LottoStats.vue";
import WinRates from "@/views/happs/lotto/WinRates.vue";
import LottoProfit from "@/views/happs/lotto/LottoProfit.vue";
import PlayLotto from "@/views/happs/lotto/PlayLotto.vue";
import ItemDrops from "@/views/happs/lotto/ItemDrops.vue";
export default Vue.extend({
    name: "VueLotto",
    components: {
        ItemDrops,
        PlayLotto,
        LottoProfit,
        WinRates,
        LottoStats,
        LottoPayment,
        DumbappModal,
        TokenContract,
        TokenAmount,
        Info,
        InfoField,
        InfoSection,
        ProgressCircle,
        ContractDropdown,
        ContractLoader,
        StyleableLayout,
    },
    mixins: [HappMixin],
    provide() {
        return {
            token: this.token,
        };
    },
    data() {
        let loaderFunction;
        loaderFunction = getLottoData;
        return {
            type: "lotto",
            commonContracts: [
                {
                    name: "Goerli Lotto",
                    bwns: 'goerli'
                }
            ],
            errorMessage: null,
            lottoData: null,
            pools: [],
            dumbapps: {},
            loaderFunction,
            loader: null,
            tokenContract: null,
            balance: null,
            token: {
                address: null,
                network: null,
                name: null,
                symbol: null,
                decimals: 0,
            },
        };
    },
    computed: {
        contractLoaded() {
            return !!this.lottoData?.contractData?.address;
        },
        lottoName() {
            return this.lottoData?.contractData?.name || "Lotto";
        },
        api() {
            return this.$store.getters["user/api"];
        },
        context() {
            return {
                data: this.lottoData,
                dumbapps: this.dumbapps,
                token: this.tokenContract,
                pools: this.pools,
                chainId: getNetwork(this.lottoData.contractData.network).chainId,
                balance: this.balance
            };
        }
    },
    watch: {
        lottoData: {
            deep: true,
            immediate: true,
            handler(val) {
                if (this.happ) {
                    getItemData(this.$store.getters["user/batches"], this.happ.web3, val).then(([balance, pools]) => {
                        this.pools = pools;
                        if (this.lottoData.fees) {
                            this.balance = balance.minus(this.lottoData.fees);
                        }
                        else {
                            this.balance = balance;
                        }
                    });
                    if (val?.token) {
                        if (!addressEqual(val.token, this.token?.address)) {
                            let chainId = getNetwork(val.contractData.network).chainId;
                            this.api.contracts
                                .getContract({ address: val.token, chainId })
                                .then((contract) => {
                                this.tokenContract = contract;
                                this.token.address = contract.address;
                                this.token.network = chainId;
                                this.token.name = contract.name;
                                this.token.symbol = contract.parameters.symbol;
                                this.token.decimals = contract.parameters.decimals;
                            });
                        }
                    }
                }
            },
        },
        "lottoData.token": {
            immediate: true,
            handler() {
                const api = this.api;
                if (this.lottoData?.contractData) {
                    api.getLottoCodes(this.lottoData.contractData.id).then((dumbapps) => {
                        this.dumbapps = dumbapps;
                    });
                }
                else {
                    this.dumbapps = {};
                }
            },
        },
    },
    methods: {
        reset() {
            this.$router.push("/lotto");
            this.interval?.clear();
            this.interval = null;
        },
        onData(data) {
            if (data) {
                this.lottoData = data;
                document.title = `${data.contractData.name} | Blockwell Wallet`;
            }
            else {
                this.lottoData = null;
                this.dumbapps = {};
                document.title = "Lotto | Blockwell Wallet";
            }
        },
    },
});
