import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import NftItemPoolDrop from "@/views/happs/lotto/NftItemPoolDrop.vue";
import Modal from "@/components/Modal.vue";
import AddNft from "@/views/happs/lotto/AddNft.vue";
export default Vue.extend({
    name: "NftItemPool",
    components: {
        AddNft,
        Modal,
        NftItemPoolDrop,
        DumbappModal,
        TokenContract,
        TokenAmount,
        Info,
        InfoField,
        InfoSection,
    },
    props: {
        context: {
            type: Object,
        },
        pool: {
            type: Object,
        },
    },
    data() {
        return {
            showAddNft: false,
            dumbapp: null
        };
    },
    computed: {
        percent() {
            return this.pool.dropRate
                .div(this.context.data.randomRange)
                .times(100)
                .sd(3)
                .toFormat();
        },
        poolIndex() {
            return this.context.pools.indexOf(this.pool).toString();
        }
    },
    watch: {
        "pool.pool": {
            immediate: true,
            handler(val) {
                const api = this.$store.getters["user/api"];
                api.lottoWithdrawNftDumbapp(`${this.context.chainId}.${val}`)
                    .then(it => this.dumbapp = it);
            }
        }
    },
    methods: {},
});
