import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import NftItemPool from "@/views/happs/lotto/NftItemPool.vue";
export default Vue.extend({
    name: "ItemDrops",
    components: { NftItemPool, DumbappModal, TokenContract, TokenAmount, Info, InfoField, InfoSection },
    props: {
        context: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    computed: {
        pools() {
            return this.context.pools || [];
        }
    },
    methods: {},
});
