import Vue from "vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import BigNumber from "bignumber.js";
import AddNftItem from "@/views/happs/lotto/AddNftItem.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import Recent from "@/lib/Recent";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit.vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
const recent = new Recent();
export default Vue.extend({
    name: "AddListing",
    components: { DumbappWallet, DumbappSubmit, DumbappPopover, AddNftItem, ProgressCircle, ContractLoader },
    props: {
        market: {
            type: Object,
        },
    },
    provide: {
        provider: null,
    },
    data() {
        return {
            account: null,
            contract: null,
            tokenContract: null,
            loadingItems: false,
            items: [],
            dumbapp: null,
            amountForm: {},
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        nftLoader() {
            return this.$store.getters["user/nftLoader"];
        },
        nftDeps() {
            return {
                account: this.account,
                contractId: this.contract?.id,
                marketId: this.market?.id,
            };
        },
        amounts() {
            return Object.fromEntries(Object.entries(this.amountForm)
                .filter(([key, val]) => new BigNumber(val).gt(0))
                .map(([key, val]) => {
                return [key.slice(7), val];
            }));
        },
        disabled() {
            return Object.keys(this.amounts).length === 0;
        },
        listQuery() {
            return {
                paymentToken: this.tokenContract.address,
                tokenIds: Object.keys(this.amounts),
                values: Object.values(this.amounts),
            };
        },
    },
    watch: {
        nftDeps: {
            deep: true,
            handler(val) {
                if (this.contract) {
                    recent.addUserRecent("listnft", this.contract);
                }
                if (val.account && val.contractId) {
                    this.loadingItems = true;
                    this.nftLoader
                        .getItems(this.$store.getters["user/batches"], this.contract, val.account)
                        .then((items) => {
                        this.items = items;
                        this.loadingItems = false;
                    });
                    this.api
                        .marketListDumbapp(val.marketId, val.contractId)
                        .then((it) => (this.dumbapp = it));
                }
                else {
                    this.items = [];
                }
            },
        },
        tokenContract(val) {
            if (val?.address) {
                recent.addUserRecent("listerc20", val);
            }
        },
    },
    methods: {
        contractSelected(contract) {
            this.contract = contract;
        },
        tokenContractSelected(contract) {
            this.tokenContract = contract;
        },
        login() {
            this.$bus.emit("login_request", {});
        },
    },
});
