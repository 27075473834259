import CryptoJS from "crypto-js";

const format = CryptoJS.format.OpenSSL;

export class FastAesCrypt {

    constructor() {
        this.dec = new TextDecoder();
    }

    async encrypt(message, data) {
        if (!data || !data.password) {
            throw new Error("Must provide an Encryption Password.");
        }

        return CryptoJS.AES.encrypt(message, data.password).toString();
    }

    async decrypt(ciphertext, data) {
        let decoded;

        let parsed = format.parse(ciphertext);

        let keys = CryptoJS.kdf.OpenSSL.execute(data.password, 256 / 32, 128 / 32, parsed.salt);

        let key = await crypto.subtle.importKey(
            "raw",
            hexToUint8Array(keys.key.toString()),
            "AES-CBC",
            true,
            ["encrypt", "decrypt"]);

        let native = await window.crypto.subtle.decrypt({
            name: "AES-CBC",
            iv: hexToUint8Array(keys.iv.toString())
        }, key, hexToUint8Array(parsed.ciphertext.toString()));

        decoded = this.dec.decode(native);

        return decoded;
    }
}

/**
 * @param {string} hex
 * @return {Uint8Array}
 */
function hexToUint8Array(hex) {
    let length = hex.length / 2;
    let arr = new Uint8Array(hex.length / 2);
    let pos = 0;

    for (let i = 0; i < length; i++) {
        arr[i] = parseInt(hex.slice(pos, pos + 2), 16);

        pos += 2;
    }

    return arr;
}
