import Two from "two.js";
import { TWO_PI } from "two.js/src/utils/math";
import TW, { Tween } from "@tweenjs/tween.js";
import BigNumber from "bignumber.js";
const SPLITS = 3;
const DOTS = 24;
export function redrawLotto(two, returns, decimals) {
    two.clear();
    let group = two.makeGroup();
    let bottom = two.makeArcSegment(0, 0, 0, 160, 0, TWO_PI, 50);
    bottom.className = "bottom";
    group.add(bottom);
    let wheelGroup = two.makeGroup().noFill().noStroke();
    group.add(wheelGroup);
    let segmentStart = 0;
    function addSegment(chance, amount, style) {
        let start = segmentStart;
        let end = segmentStart + chance * TWO_PI;
        let segment = two.makeArcSegment(0, 0, 10, 150, start, end + 0.01);
        wheelGroup.add(segment);
        segment.className = "segment segment-" + ((style % 9) + 1);
        segmentStart = end;
        let message = amount.div(`1e${decimals}`).toFormat();
        let angle = start + (end - start) / 2;
        let x = Math.cos(angle) * 140;
        let y = Math.sin(angle) * 140;
        let text = two.makeText(message, x, y, {
            size: 11,
            weight: 600,
            fill: "white",
            alignment: "right",
        });
        text.rotation = angle;
        wheelGroup.add(text);
    }
    group.position.set(200, two.height / 2);
    let winRange = returns.reduce((acc, it) => acc.plus(it.chance), new BigNumber(0)).toNumber();
    let loseRange = (10000 - winRange) / 10000;
    for (let split = 0; split < SPLITS; split++) {
        let i = 0;
        for (let it of returns) {
            let chance = it.chance.div(10000).toNumber();
            if (chance < 0.05) {
                if (split === 0) {
                    addSegment(chance, it.amount, i++);
                }
                else {
                    i++;
                }
            }
            else {
                addSegment(chance / SPLITS, it.amount, i++);
            }
        }
        if (loseRange > 0) {
            addSegment(loseRange / SPLITS, new BigNumber(0), -1);
        }
    }
    let circle = two.makeCircle(0, 0, 40);
    wheelGroup.add(circle);
    circle.className = "circle";
    let sprite = new Two.Sprite(null, 0, 0, 1, 1);
    sprite.texture = new Two.Texture(require("../../../assets/images/lion-white.png"), function () {
        setTimeout(function () {
            if (sprite.width > 0 && sprite.height > 0) {
                if (sprite.width / sprite.height > 50 / 62) {
                    sprite.scale = 50 / sprite.width;
                }
                else {
                    sprite.scale = 62 / sprite.height;
                }
                two.update();
            }
        }, 0);
    });
    wheelGroup.add(sprite);
    let wheel = two.makeArcSegment(0, 0, 149, 160, 0, TWO_PI, 40);
    wheelGroup.add(wheel);
    wheel.className = "wheel";
    for (let i = 0; i < DOTS; i++) {
        let x = Math.sin((TWO_PI * i) / DOTS) * 154;
        let y = Math.cos((TWO_PI * i) / DOTS) * 154;
        let dot = two.makeCircle(x, y, 4);
        dot.className = "dot";
        wheelGroup.add(dot);
    }
    let svg = document.querySelector(".lotto-arrow");
    let arrow = two.interpret(svg);
    arrow.position.set(200, 20);
    two.update();
    return {
        two,
        wheel: wheelGroup,
        arrow,
    };
}
export function rotateLotto({ two, wheel, arrow }) {
    TW.removeAll();
    let start = new Tween({ rotation: 0 })
        .to({ rotation: TWO_PI }, 2000)
        .easing(TW.Easing.Cubic.In)
        .onUpdate((pos, elapsed) => {
        wheel.rotation = pos.rotation;
    });
    let rotate = new Tween({ rotation: 0 })
        .to({ rotation: TWO_PI }, 900)
        .repeat(200)
        .onUpdate((pos, elapsed) => {
        wheel.rotation = pos.rotation;
    });
    start.onComplete(() => {
        rotate.start();
    });
    start.start();
    two.bind("update", function () {
        TW.update();
    }).play();
    return rotate;
}
export function finishLotto(graphics, returns, index) {
    let { two, wheel, rotate } = graphics;
    // The final position is 2 minus this, and then minus 0.25 because the arrow is at the
    // top - hence the 1.75.
    // It's from 2 instead of 1 because we don't want it to ever be negative due to the 0.25 adjustment
    let pos = (1.75 - calculatePosition(returns, index)) * TWO_PI;
    return new Promise((resolve) => {
        let partial = new Tween({ rotation: 0 })
            .to({ rotation: pos }, (900 * pos) / TWO_PI)
            .onUpdate((pos, elapsed) => {
            wheel.rotation = pos.rotation;
        });
        graphics.partial = partial;
        let slowdown = new Tween({ rotation: pos })
            .to({ rotation: pos + TWO_PI }, 2000)
            .easing(TW.Easing.Cubic.Out)
            .onUpdate((pos, elapsed) => {
            wheel.rotation = pos.rotation;
        })
            .onComplete(() => {
            resolve();
        });
        graphics.slowdown = slowdown;
        partial.onComplete(() => {
            slowdown.start();
        });
        rotate.onRepeat(() => {
            rotate.stop();
            partial.start();
        });
    });
}
function calculatePosition(returns, index) {
    let winRange = returns.reduce((acc, it) => acc.plus(it.chance), new BigNumber(0)).toNumber();
    let loseRange = (10000 - winRange) / 10000;
    let randomSplit = Math.floor(Math.random() * SPLITS);
    let pos = 0;
    for (let split = 0; split < SPLITS; split++) {
        let i = 0;
        for (let it of returns) {
            let chance = it.chance.div(10000).toNumber();
            // If the chance is less than 5% the wedge isn't split
            if (chance < 0.05) {
                if (index === i) {
                    return pos + Math.random() * chance;
                }
                else if (split === 0) {
                    // Add the position if this is the first split, since that's the only place
                    // a win chance with less than 5% is present
                    pos += chance;
                }
            }
            else if (index === i && randomSplit === split) {
                return pos + (Math.random() * chance) / SPLITS;
            }
            else {
                pos += chance / SPLITS;
            }
            ++i;
        }
        if (loseRange > 0) {
            if (index === -1 && randomSplit === split) {
                return pos + (Math.random() * loseRange) / SPLITS;
            }
            pos += loseRange / SPLITS;
        }
    }
}
