import PageMessages from "@/components/message/PageMessages";
import MobileNav from "@/layouts/components/MobileNav";
import WhitelabelFooter from "@/layouts/components/WhitelabelFooter";
import WhitelabelMainHeader from "@/layouts/components/WhitelabelMainHeader";
import WhitelabelMobileNav from "@/layouts/components/WhitelabelMobileNav";
import Vue from "vue";
export default Vue.extend({
    name: "WhitelabelHeading",
    components: { WhitelabelMainHeader, WhitelabelMobileNav, MobileNav, WhitelabelFooter, PageMessages },
    props: {
        size: String,
        noMessages: Boolean,
        vars: Object,
        icon: String,
        external: String
    },
    computed: {
        sizeClass() {
            if (this.size) {
                return "content-width-" + this.size;
            }
            else {
                return "";
            }
        },
    },
});
