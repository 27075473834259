<template>
    <layout-heading>
        <portal to="title">
            <router-link to="/nft">
                <img src="../../../assets/images/icons/nft.svg" alt="">
                <span>Non-Fungible Tokens</span>
            </router-link>
        </portal>
        <template #heading-cta>
            <ContractDropdown v-if="contractLoaded"
                              @change-contract="reset"
                              :name="nftData.name"
                              :address="address"
                              :network="network"
            />
        </template>

        <p>
            A non-fungible token is a unique item with its own identity. Unlike with coin-like tokens,
            one NFT is not interchangeable with another.
            <br>
            <router-link to="/forums">Create a Non-Fungible Token contract</router-link>
        </p>

        <div v-if="errorMessage || happError" class="results error">{{ errorMessage || happError }}</div>

        <ContractLoader v-if="!contractLoaded"
                        name="NFT"
                        path="nft"
                        features="erc721"
                        :deployer="deployerAddress"
                        :common="commonContracts" />

        <div v-else>
            <div class="mint-token-wrap">
                <button class="btn btn-primary" @click="showMint = true">Mint New Token</button>
                <div class="mint-token-description">
                    Minting allows you to create a new item for {{ nftData.name }}.
                </div>
            </div>

            <div v-if="tokenList && tokenList.length > 0" class="nft-list">
                <Pagination :pages="pages" :page.sync="page" />
                <NftGrid :tokens="tokenList" :nft-data="nftData" :dumbapps="dumbapps" />
                <Pagination :pages="pages" :page.sync="page" />
            </div>
            <div v-else class="no-tokens">
                No tokens yet, <a href="javascript: void 0;" @click="showMint = true">Mint One</a>!
            </div>

        </div>

        <template v-if="contractLoaded">
            <Modal :show.sync="showMint">
                <MintNft :nft-data="nftData"
                         @submitted="showMint = false"
                         :dumbapps="dumbapps"
                />
            </Modal>
        </template>
    </layout-heading>
</template>

<script>
import { getNetwork } from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import { DumbappSubmission } from "@blockwell/dumbapp";
import DumbappPopover from "@/components/DumbappPopover";
import Modal from "@/components/Modal";
import Pagination from "@/components/Pagination";
import ProgressCircle from "@/components/ProgressCircle";
import TokenAmount from "@/components/TokenAmount";
import ContractDropdown from "@/views/happs/components/ContractDropdown";
import ContractLoader from "@/views/happs/components/ContractLoader";
import HappMixin, { Happ } from "@/views/happs/Happ";
import { loadNftContract, loadNfTokens, NftData, NfToken } from "@/views/happs/nft/lib/nftlib";
import MintNft from "@/views/happs/nft/MintNft";
import NftGrid from "@/views/happs/nft/NftGrid";
import { clone } from "rambdax";
import { mapGetters, mapState } from "vuex";

export default {
    components: {
        NftGrid,
        Pagination,
        MintNft,
        Modal, ProgressCircle, DumbappPopover, Clipboard, TokenAmount, ContractLoader, ContractDropdown
    },
    mixins: [HappMixin],
    data() {
        return {
            type: "nft",
            deployerAddress: [{
                address: "0xb490c3461512CC05E20F7e7D41BcCf65E114AEC2",
                network: 5
            }, "0xC0DEeD81ABD93Fc2Ac38a439da811B36532D7BA8"],
            commonContracts: [{
                name: "Non-Fungible Playground",
                bwns: "playground"
            }],
            errorMessage: null,
            nftData: new NftData(),
            dumbapps: {},
            showMint: false,
            totalCount: 0,
            pageSize: 9,
            tokens: []
        };
    },
    computed: {
        ...mapState("user", ["account"]),
        ...mapGetters("user", ["api"]),
        ...mapGetters("dumbapp", ["tracking"]),
        contractLoaded() {
            return !!this.nftData.address;
        },
        pages() {
            return Math.ceil(this.totalCount / this.pageSize);
        },
        page: {
            get() {
                let page = this.$route.query.page;
                return page ? parseInt(page) : 1;
            },
            set(val) {
                let query = {};
                if (val > 1) {
                    query.page = val;
                }
                this.$router.push({ query });
            }
        },
        tokensQuery() {
            return {
                totalCount: this.totalCount,
                page: this.page,
                pageSize: this.pageSize
            };
        },
        tokensQueryDeps() {
            return {
                query: this.tokensQuery,
                address: this.nftData?.address,
                network: this.nftData?.network,
                bwtype: this.nftData?.bwtype,
                bwver: this.nftData?.bwver
            };
        },
        tokenList() {
            let trackingId = ["nft-mint", this.nftData.network.networkId, this.nftData.address].join("-");
            let tracks = this.tracking[trackingId];

            let tokens = [];
            if (tracks) {
                tokens = tokens.concat(tracks.filter(it => it.status === "pending").map(it => {
                    let token = new NfToken();
                    token.tokenId = it.tokenId;
                    token.pending = it;
                    return token;
                }));
            }

            tokens = tokens.concat(this.tokens);
            return tokens;
        }
    },
    asyncComputed: {
        smartData() {
            return null;
        }
    },
    watch: {
        happ: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadData(val);
                } else {
                    this.nftData = new NftData();
                }
            }
        },
        tokensQueryDeps: {
            deep: true,
            handler(val) {
                if (val.network && val.address) {
                    this.loadTokens(val);
                }
            }
        }
    },
    methods: {
        loadData(happ) {
            loadNftContract(happ, this.account)
                .then(data => {
                    this.errorMessage = null;
                    this.nftData = data;
                    this.totalCount = data.totalSupply.toNumber();

                    if (this.loggedIn) {
                        this.api.addToContractHistory(
                            this.$route.fullPath,
                            "NFT happ",
                            happ.address,
                            happ.network.networkId
                        );
                    }

                    this.api.getNftDumbapps(data.contractId)
                        .then(dumbapps => this.dumbapps = dumbapps)
                        .catch(console.error);
                })
                .catch(err => {
                    console.error(err);
                    this.errorMessage = "Failed to load contract: " + err.message;
                });
        },
        reset() {
            this.$router.push("/nft");
        },
        dumbappCompleted(submission) {
            let i = 0;
            let refresh = false;
            for (let step of submission.data.steps) {
                let dumbappStep = submission.dumbapp.steps[i];
                if (getNetwork(dumbappStep.network).networkId === this.nftData.network.networkId
                    && dumbappStep.address.toLowerCase() === this.nftData.address.toLowerCase()) {
                    for (let event of step.events) {
                        if (event && event.event === "Transfer") {
                            refresh = true;
                        }
                    }
                }
                ++i;
            }

            if (refresh) {
                this.loadTokens(this.tokensQueryDeps);
            }
        },
        loadTokens(deps) {
            loadNfTokens(deps.network, deps.address, deps.bwtype, deps.bwver, (deps.query.page - 1) * deps.query.pageSize, deps.query.pageSize)
                .then(it => {
                    if (it.totalCount !== this.totalCount) {
                        this.totalCount = it.totalCount;
                    }
                    this.tokens = it.tokens;
                });
        }
    },
    mounted() {
        this.$bus.on("dumbapp-completed", this.dumbappCompleted);
    },
    beforeDestroy() {
        this.$bus.off("dumbapp-completed", this.dumbappCompleted);
    }
};
</script>

<style scoped lang="scss">
.mint-token-wrap {
    display: flex;
    align-items: center;
}

.mint-token-description {
    font-size: 18px;
    margin-left: 10px;
}

.nft-list {
    margin-top: 30px;
}

.no-tokens {
    margin-top: 50px;
    font-size: 20px;
}

</style>
