import Vue from "vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import Info from "@/components/Info.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import TokenContract from "@/components/TokenContract.vue";
import DumbappModal from "@/components/DumbappModal.vue";
import { exportLottoStats } from "@/views/happs/lotto/lotto-lib";
import moment from "moment";
import { saveAs } from "file-saver";
import LoadingButton from "@/components/LoadingButton.vue";
export default Vue.extend({
    name: "LottoStats",
    components: { LoadingButton, DumbappModal, TokenContract, TokenAmount, Info, InfoField, InfoSection },
    props: {
        context: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        exportPlays() {
            const context = this.context;
            this.loading = true;
            exportLottoStats(context.data, context.token).then((csv) => {
                let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
                let date = moment().format("yyyy-MM-DD-HH-ss");
                saveAs(blob, `lotto-stats-${context.data.contractData.name}-${date}.csv`);
            }).finally(() => this.loading = false);
        },
    },
});
