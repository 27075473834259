import BookContentMixin from "@/components/BookContentMixin";
import Md from '@/assets/lib/md';
import ProgressCircle from "@/components/ProgressCircle.vue";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";
import Vue from "vue";
export default Vue.extend({
    name: "BookContent",
    components: { ProgressCircle },
    mixins: [CryptMixin, BookContentMixin],
    created() {
        Md().then(it => this.md = it);
    }
});
