import Vue from "vue";
import NftItem from "@/components/NftItem.vue";
export default Vue.extend({
    name: "NftSwapItem",
    components: { NftItem },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
});
