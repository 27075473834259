var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mint-nft"},[_c('h3',[_vm._v("Mint a new token")]),_c('p',[_vm._v(" Minting a new token adds a new unique item to "+_vm._s(_vm.nftData.name)+". Fill in the details below. ")]),(!_vm.loggedIn)?_c('div',[_vm._m(0),_c('LoginForm')],1):[(!_vm.canMint)?_c('p',{staticClass:"error"},[_vm._v(" You are not allowed to mint tokens on this contract. You will need to ask an admin to give you that right. ")]):_vm._e(),_c('FormulateInput',{attrs:{"label":"Name","help":"This is the name of the item this token represents.","type":"text","name":"name","validation":"required"},on:{"blur":_vm.blur},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('FormulateInput',{attrs:{"label":"Mint To Wallet (Optional)","help":"Wallet to mint the token to. If left empty, it will be minted to your wallet.","type":"text","name":"account"},on:{"blur":_vm.blur},model:{value:(_vm.wallet),callback:function ($$v) {_vm.wallet=$$v},expression:"wallet"}}),_c('FormulateInput',{attrs:{"label":"Image","help":"Paste in a link to the image for this item.","type":"text","name":"image"},on:{"blur":_vm.blur},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),(_vm.image)?_c('div',{staticClass:"image-preview-wrap"},[_c('h6',[_vm._v("Image Preview")]),(_vm.imagePreview)?_c('div',{staticClass:"image-preview"},[_c('img',{attrs:{"src":_vm.imagePreview}})]):_c('div',[_vm._v(" Not a valid image link, please use a web link to an image. ")])]):_vm._e(),_c('h6',[_vm._v("Description")]),_c('MarkdownEditor',{attrs:{"content":_vm.description,"min-height":"60","toolbar":['bold', 'italic', 'heading', 'quote', 'unordered-list', 'ordered-list',
                        'link', 'image', 'preview', 'guide']},on:{"update:content":function($event){_vm.description=$event},"blur":_vm.blur}}),_c('div',{staticClass:"formulate-input-help"},[_vm._v(" The description is shown in the details of this item. ")]),_c('h5',[_vm._v("Attributes and Traits")]),_c('p',[_vm._v("You can add Properties, Stats, Levels and Boosts to your token.")]),_c('FormulateInput',{attrs:{"type":"group","name":"attributes","repeatable":true,"remove-label":"Remove Attribute","remove-position":"after","add-label":"+ Add Attribute"},model:{value:(_vm.attributes),callback:function ($$v) {_vm.attributes=$$v},expression:"attributes"}},[_c('div',{staticClass:"attribute"},[_c('FormulateInput',{attrs:{"name":"trait_type","label":"Attribute name","help":"Name of the attribute."},on:{"blur":_vm.blur}}),_c('FormulateInput',{attrs:{"type":"select","name":"display_type","placeholder":"Select a type","label":"Type","help":"Determines how the attribute is displayed.","options":{
                        property: 'Property',
                        number: 'Stat',
                        level: 'Level',
                        boost_number: 'Boost Number',
                        boost_percentage: 'Boost Percentage'
                    }},on:{"change":_vm.blur}}),_c('FormulateInput',{attrs:{"name":"value","label":"Value","help":"Attribute value. This should be a number for Stats, Levels and Boosts, and text for Properties."},on:{"blur":_vm.blur}})],1)]),_c('LoadingButton',{staticClass:"mint-submit",attrs:{"loading":_vm.loading,"disabled":!_vm.name || _vm.loading,"small":""},on:{"click":_vm.submit}},[_vm._v("Submit")]),_c('DumbappPopover',{attrs:{"instance":_vm.mintDumbapp,"show":_vm.showDumbapp,"values":{
                tokenId: _vm.tokenId,
                uri: _vm.tokenUri,
                to: _vm.wallet
            }},on:{"update:show":function($event){_vm.showDumbapp=$event},"submitted":_vm.submitted}})]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("You must be logged in to mint a token.")])])
}]

export { render, staticRenderFns }