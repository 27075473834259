import CryptoJS from "crypto-js";

export class AesCrypt {

    encrypt(message, data) {
        if (!data || !data.password) {
            throw new Error("Must provide an Encryption Password.");
        }
        return CryptoJS.AES.encrypt(message, data.password).toString();
    }

    decrypt(ciphertext, data) {
        return CryptoJS.AES.decrypt(ciphertext, data.password).toString(CryptoJS.enc.Utf8);
    }
}
