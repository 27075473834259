import Vue from "vue";
import Info from "../../../components/Info.vue";
import ClampedText from "../../../components/ClampedText.vue";
import DumbappPopover from "../../../components/DumbappPopover.vue";
import BigNumber from "bignumber.js";
import Markdown from "../../../components/Markdown.vue";
import MarkdownEditor from "../book/editors/MarkdownEditor.vue";
import { capitalCase } from "change-case";
export default Vue.extend({
    name: "Suggestion",
    components: { MarkdownEditor, Markdown, DumbappPopover, ClampedText, Info },
    props: {
        suggestion: {
            type: Object,
        },
        dumbapps: {
            type: Object,
        },
        votingData: {
            type: Object
        },
        language: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        userVotes() {
            return this.suggestion?.user?.votes || new BigNumber(0);
        },
        votesLeft() {
            return this.votingData?.votesLeft;
        },
        commentCount() {
            return this.suggestion?.comments?.length || 0;
        }
    },
    methods: {
        comments() {
            this.$emit("comments", this.suggestion.id);
        },
        capitalize(text) {
            return capitalCase(text);
        },
    },
});
