import Vue from "vue";
import MarketItem from "@/views/happs/market/MarketItem.vue";
export default Vue.extend({
    name: "MarketList",
    components: { MarketItem },
    props: {
        listings: {
            type: Array,
        },
        market: {
            type: Object
        }
    },
});
