import Vue from "vue";
import TokenAmount from "@/components/TokenAmount.vue";
import DumbappPopover from "@/components/DumbappPopover.vue";
import NftItem from "@/components/NftItem.vue";
export default Vue.extend({
    name: "AddNftItem",
    components: { NftItem, DumbappPopover, TokenAmount },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        }
    },
    watch: {},
});
