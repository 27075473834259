import Vue from "vue";
import VoteComment from "@/views/happs/vote/VoteComment.vue";
import { capitalCase } from "change-case";
import Markdown from "@/components/Markdown.vue";
export default Vue.extend({
    name: "SuggestionDetail",
    components: { Markdown, VoteComment },
    props: {
        language: {
            type: Object,
        },
        suggestion: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    methods: {
        capitalize(text) {
            return capitalCase(text);
        },
    },
});
