import BigNumber from "bignumber.js";
import { Batcher, hasHex } from "@/lib/eth/Batcher";
import nftAbi from "@/assets/data/NfToken.abi.json";
import { BatchRequest } from "web3-providers";
import poolAbi from "@/assets/data/NftItemPool.v79.abi.json";
import erc20Abi from "@/assets/data/Erc20.abi.json";
import Dapp from "@/assets/lib/Dapp";
import oldAbi from "@/assets/data/ItemDrops.abi.json";
import abi from "@/assets/data/ItemDrops.v67.abi.json";
import erc20 from "@/assets/data/Erc20.abi.json";
import moment from "moment";
import { addTokenURIs } from "@blockwell/nft";
import { Chain } from "@blockwell/chain-client";
import { getNetwork } from "@/assets/lib/networks";
import { AbiDecoderData, Decoder } from "@blockwell/eth-decoder";
import { ethResolveBooleanParameter, ethResolveStringParameter, } from "@blockwell/eth-types";
export async function getLottoData({ web3, contract, account, cache, firstLoad, }) {
    let batcher = new Batcher(web3)
        .setContract(contract.abi, contract.address)
        .addBig("randomRange")
        .add("token")
        .addBig("price")
        .tryBig("fees")
        .tryBig("maxMultiPlays")
        .addBoolean("useReturns")
        .addInt("freePlays")
        .addBoolean("paused")
        .tryStructArray("getPools", "pools")
        .addBigArray("getReturnChances")
        .addBigArray("getReturnAmounts");
    let res = await batcher.execute();
    return {
        ...res,
        contractData: contract,
        returns: res.getReturnChances.map((it, index) => {
            return {
                chance: it,
                amount: res.getReturnAmounts[index],
            };
        }),
    };
}
export async function getItemData(batches, web3, data) {
    let batch = new BatchRequest(web3);
    let tokenContract = new web3.eth.Contract(erc20Abi, data.token);
    batch.add(tokenContract.methods.balanceOf(data.contractData.address).call.request());
    if (data.pools?.length > 0) {
        for (let pool of data.pools) {
            let contract = new web3.eth.Contract(poolAbi, pool.pool);
            batch.add(contract.methods.name().call.request());
            batch.add(contract.methods.items().call.request());
        }
    }
    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    }
    catch (err) {
        console.log("Caught error in batch calling", err.message);
        res = err.response.map((it) => (it.jsonrpc ? it.result : it));
    }
    let chainId = getNetwork(data.contractData.network).chainId;
    let balance = new BigNumber(res.shift());
    let pools = [];
    if (data.pools?.length > 0) {
        let allItems = [];
        let i = 0;
        for (let pool of data.pools) {
            let name = res[i++];
            let items = res[i++].map((it) => {
                return Object.fromEntries(Object.entries(it)
                    .map(([key, val]) => {
                    if (!/^\d+$/.test(key)) {
                        if (hasHex(val)) {
                            return [key, new BigNumber(val._hex).toString(10)];
                        }
                        else {
                            return [key, val];
                        }
                    }
                    return null;
                })
                    .filter((it) => !!it));
            });
            for (let item of items) {
                item.chainId = chainId;
            }
            allItems = allItems.concat(items);
            pools.push({
                pool: pool.pool,
                dropRate: pool.dropRate,
                items,
                name,
            });
        }
        await addTokenURIs(batches, allItems);
    }
    return [balance, pools];
}
export async function exportLottoRates(data, token) {
    let csv = "chance,amount";
    let col3 = ["Price", "Average Win", "Profit", "House Edge"];
    let col4 = [
        new BigNumber(data.price).div(`1e${token.parameters.decimals}`).toString(10),
        '"=SUMPRODUCT(A2:A100, B2:B100)"',
        "=E2-E3",
        "=1-E3/E2",
    ];
    let i = 0;
    for (let ret of data.returns) {
        let chance = ret.chance.div(10000);
        let amount = ret.amount.div(`1e${token.parameters.decimals}`);
        csv +=
            "\n" +
                [
                    chance.isNaN() ? "" : chance.toString(10),
                    amount.isNaN() ? "" : amount.toString(10),
                    "",
                    col3[i],
                    col4[i],
                ].join(",");
        ++i;
    }
    return csv;
}
export async function exportLottoStats(data, token) {
    if (data.contractData.bwver > 77) {
        let stats = await loadPlayStats(data.contractData, token);
        let csv = "player,timestamp,multiplier,bet,win,transaction hash,item drops";
        for (let row of stats) {
            csv += "\n" + [row.player, row.timestamp, row.multiplier, row.bet, row.win, row.hash, row.itemDrop].join(",");
        }
        return csv;
    }
    let stats = await loadLottoStats(data.contractData, token);
    let csv = "player,timestamp,bet,win,transaction hash";
    for (let row of stats) {
        csv += "\n" + [row.player, row.timestamp, row.bet, row.win, row.transactionHash].join(",");
    }
    return csv;
}
export async function loadPlayStats(contract, token) {
    let net = Dapp.getNetwork(contract.network);
    let web3 = net.web3;
    let c = new web3.eth.Contract(contract.abi, contract.address);
    // event Play(address indexed player, uint256 payment, uint256 win, bool items);
    let events = await c.getPastEvents("Play", {
        fromBlock: 0,
        toBlock: "latest",
    });
    let batch = new web3.BatchRequest();
    for (let event of events) {
        // @ts-ignore
        batch.add(web3.eth.getTransaction.request(event.transactionHash));
    }
    for (let event of events) {
        // @ts-ignore
        batch.add(web3.eth.getBlock.request(event.blockNumber));
    }
    let res = await batch.execute();
    let txs = res.response.slice(0, events.length);
    let blocks = res.response.slice(events.length);
    for (let i = 0; i < txs.length; i++) {
        let timestamp = blocks[i].timestamp;
        txs[i].timestamp = typeof timestamp === "number" ? timestamp : parseInt(timestamp);
    }
    let plays = [];
    for (let i = 0; i < events.length; i++) {
        let event = events[i];
        let tx = txs[i];
        let multiplier = "";
        if (tx.input.startsWith("0x5be319ee")) {
            multiplier = new BigNumber("0x" + tx.input.slice(-64)).toString(10);
        }
        else if (tx.input.startsWith("0x93e84cd9") || tx.input.startsWith("0xed82ca4d")) {
            multiplier = "1";
        }
        plays.push({
            player: event.returnValues.player,
            timestamp: moment.unix(tx.timestamp).format("YYYY-MM-DD HH:mm"),
            multiplier,
            bet: new BigNumber(event.returnValues.payment).div(`1e${token.parameters.decimals}`).toString(10),
            win: new BigNumber(event.returnValues.win).div(`1e${token.parameters.decimals}`).toString(10),
            hash: event.transactionHash,
            itemDrop: event.returnValues.items ? "yes" : "no"
        });
    }
    return plays;
}
export async function loadLottoStats(contract, token) {
    let net = Dapp.getNetwork(contract.network);
    let web3 = net.web3;
    let c = new web3.eth.Contract(oldAbi, contract.address);
    if (contract.bwver > 59) {
        c = new web3.eth.Contract(abi, contract.address);
    }
    let t = new web3.eth.Contract(erc20, token.address);
    let events = await t.getPastEvents("Transfer", {
        fromBlock: 0,
        toBlock: "latest",
        filter: {
            to: contract.address,
        },
    });
    let batch = new web3.BatchRequest();
    for (let event of events) {
        // @ts-ignore
        batch.add(web3.eth.getTransactionReceipt.request(event.transactionHash));
    }
    for (let event of events) {
        // @ts-ignore
        batch.add(web3.eth.getBlock.request(event.blockNumber));
    }
    let res = await batch.execute();
    let receipts = res.response.slice(0, events.length);
    let blocks = res.response.slice(events.length);
    for (let i = 0; i < receipts.length; i++) {
        receipts[i].timestamp = blocks[i].timestamp;
    }
    let plays = receipts.filter((it) => it.to.toLowerCase() === contract.address.toLowerCase());
    let decoder = new Decoder(new AbiDecoderData([...erc20, ...abi]));
    for (let play of plays) {
        play.logs = await decoder.logs(play.logs);
    }
    return plays.map((it) => {
        let logs = it.logs;
        let row = {
            player: it.from,
            timestamp: moment.unix(it.timestamp).format("yyyy-MM-DD HH:mm"),
            bet: "0",
            win: "0",
            transactionHash: it.transactionHash,
        };
        row.bet = new BigNumber(ethResolveStringParameter(logs[0].params, "2"))
            .div(`1e${token.parameters.decimals}`)
            .toString(10);
        let win = logs.find((log) => log.event === "TokenWin");
        if (win) {
            row.win = new BigNumber(ethResolveStringParameter(win.params, "value"))
                .div(`1e${token.parameters.decimals}`)
                .toString(10);
        }
        return row;
    });
}
export async function addItemWins(play, sub, win) {
    if (ethResolveBooleanParameter(play.params, "items")) {
        let transfer = sub.data.extras?.transfers?.find((it) => it.type === "erc721");
        if (transfer) {
            let item = {
                chainId: transfer.chainId,
                nft: transfer.address,
                tokenId: transfer.tokenId,
            };
            win.items.push(item);
            item.uri = await Chain.readString(item.chainId, item.nft, nftAbi, "tokenURI", [item.tokenId], 180 * 1000);
        }
    }
}
