import { render, staticRenderFns } from "./NftSwap.vue?vue&type=template&id=198c9024&scoped=true&"
import script from "./NftSwap.vue?vue&type=script&lang=ts&"
export * from "./NftSwap.vue?vue&type=script&lang=ts&"
import style0 from "./NftSwap.vue?vue&type=style&index=0&id=198c9024&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "198c9024",
  null
  
)

export default component.exports