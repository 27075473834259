import Vue from "vue";
import PendingSwapsList from "@/views/happs/nftswap/PendingSwapsList.vue";
import NftSwapList from "@/views/happs/nftswap/NftSwapList.vue";
import { chainEqual } from "@blockwell/chains";
export default Vue.extend({
    name: "NftSwapAccount",
    components: { NftSwapList, PendingSwapsList },
    provide() {
        return {
            forceWalletType: this.forceWalletType
        };
    },
    props: {
        account: String,
        walletType: String,
        contracts: {
            type: Array
        },
        swapData: {
            type: Object
        }
    },
    data() {
        return {
            forceWalletType: {
                type: this.walletType
            }
        };
    },
    watch: {
        walletType(val) {
            this.forceWalletType.type = val;
        }
    },
    methods: {
        toChain(contract) {
            return this.swapData?.contracts?.filter((it) => !chainEqual(it.network, contract.network))?.[0];
        },
    }
});
