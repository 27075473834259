<template>
    <div class="info-section">
        <h4 class="info-heading">
            <slot name="title">{{ title }}</slot>
        </h4>
        <div class="info-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoSection",
    props: {
        title: String
    },
    data() {
        return {};
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.info-heading {
    background: var(--info-heading-background);
    color: var(--info-heading-text-color);
    padding: 10px;
    margin-top: 10px;
    font-size: 18px;
    border-bottom: 1px solid var(--info-heading-bottom-border);
}

.info-body {
    padding: 0 8px 16px;
}

.info-section {
    ::v-deep() {
        .buttons {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            flex-wrap: wrap;
        }
    }
}

</style>
