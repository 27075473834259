import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import abi from "../../../../assets/data/ForumToken.abi.json";
export class NftData {
    constructor() {
        this.dumbapps = {};
    }
}
export class NfToken {
}
export async function loadNftContract(happ, account) {
    let batcher = new Batcher(happ.web3);
    batcher
        .setContract(abi, happ.address)
        .add("name")
        .add("symbol")
        .addBig("totalSupply")
        .addInt("bwver")
        .addInt("bwtype");
    if (account) {
        batcher
            .addBoolean("isAdmin", "admin", [account])
            .addBoolean("isMinter", "minter", [account]);
    }
    let data = (await batcher.execute(NftData));
    data.address = happ.address;
    data.network = happ.network;
    data.contractId = happ.contractId;
    return data;
}
export async function nextNftId(network, address) {
    return await Chain.readString(network, address, abi, "totalSupply", [], 0);
}
export async function loadNfTokens(network, address, bwtype, bwver, from, count) {
    let fromIndex = from;
    let toIndex = from + count;
    const web3 = Dapp.getNetwork(network).web3;
    let batcher = new Batcher(web3);
    batcher.setContract(abi, address);
    batcher.addBig("totalSupply");
    // Optimized retrieval method in newer versions
    if ((bwver > 48 && bwtype === 13) || ((bwtype === 26 || bwtype === 27 || bwtype === 35) && bwver > 63)) {
        batcher.addObjectList("tokens", toIndex, {
            tokenId: {
                method: "tokenByIndex",
            },
            owner: {
                method: "ownerOfByIndex",
            },
            uri: {
                method: "tokenURIByIndex",
            },
        }, fromIndex, NfToken);
    }
    else if (bwtype === 26 || bwtype === 27) {
        batcher.addObjectList("tokens", toIndex, {
            tokenId: {
                method: "tokenByIndex",
            },
            owner: {
                method: "ownerOfByIndex",
            },
        }, fromIndex, NfToken);
    }
    let res = await batcher.execute();
    let tokens = res.tokens;
    if (res.totalSupply.lte(toIndex)) {
        tokens = tokens.slice(0, res.totalSupply.minus(fromIndex).toNumber());
    }
    if (bwtype === 26 || bwtype === 27) {
        let batcher = new Batcher(web3)
            .setContract(abi, address)
            .addList("tokenURI", false, tokens.length, {
            argsList: tokens.map((it) => [it.tokenId]),
        });
        let res = await batcher.execute();
        let i = 0;
        for (let token of tokens) {
            token.uri = res.tokenURI[i];
            ++i;
        }
    }
    return {
        totalCount: res.totalSupply.toNumber(),
        tokens,
    };
}
