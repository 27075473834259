import Vue from "vue";
import BigNumber from "bignumber.js";
import NftMixin from "@/components/NftMixin";
export default Vue.extend({
    name: "NftItem",
    mixins: [NftMixin],
    props: {
        idLabel: String,
        itemId: BigNumber,
        valueLabel: String,
        minimal: Boolean
    },
    data() {
        return {};
    }
});
