import BigNumber from "bignumber.js";
export class TokenLock {
    constructor(value = null, expiration = null) {
        this.periodLength = 0;
        this.periods = 0;
        this.value = value;
        this.expiration = expiration;
    }
}
export class LockData {
    constructor(locks, lockedTotal, softTotal) {
        this.locks = locks;
        this.lockedTotal = lockedTotal;
        this.softTotal = softTotal;
    }
}
export class LockUser {
    constructor(address, balance, availableBalance, unlocked, lockData) {
        this.expanded = false;
        this.address = address;
        this.balance = balance;
        this.availableBalance = availableBalance;
        this.unlocked = unlocked;
        this.locks = lockData.locks;
        this.nextUnlock = lockData.nextUnlock;
        this.softTotal = lockData.softTotal || new BigNumber(0);
        this.lockedTotal = lockData.lockedTotal;
    }
}
export class StakingNft {
    constructor(data) {
        Object.assign(this, data);
    }
}
export class StakingData {
    constructor(networkId, address) {
        this.compounding = true;
        this.networkId = networkId;
        this.address = address;
    }
}
