import { render, staticRenderFns } from "./PlayLotto.vue?vue&type=template&id=60dab1ec&scoped=true&"
import script from "./PlayLotto.vue?vue&type=script&lang=ts&"
export * from "./PlayLotto.vue?vue&type=script&lang=ts&"
import style0 from "./PlayLotto.vue?vue&type=style&index=0&id=60dab1ec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60dab1ec",
  null
  
)

export default component.exports